import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { useSpring } from '@react-spring/core';
import { useLoading, useSteps } from '../../stores';
import { animated as a } from 'react-spring';
import { springs, textStyles } from '../../commonStyles';
import LoadingBar from './LoadingBar';
import { base } from '../../constants';

export const Loading = () => {
    const loader = useLoading();
    const steps = useSteps();

    const { opacity } = useSpring({
        from: { opacity: 1 },
        opacity:
            loader.progress >= loader.totalItems || steps.step === 0 ? 0 : 1,
        config: springs.cutscene,
        delay:
            loader.progress >= loader.totalItems || steps.step === 0 ? 1400 : 0
    });

    return steps.step !== 0 ? (
        <a.div
            style={{
                opacity,
                pointerEvents:
                    loader.progress >= loader.totalItems || steps.step === 0
                        ? 'none'
                        : 'auto'
            }}
            className={css(styles.container)}
        >
            <div className={css(textStyles.heading32)}>
                <img
                    className={css(styles.car)}
                    src= {base + "/content/loadingIcon.png"}
                    alt="carLoadingImage"
                />
                <LoadingBar amount={loader.suspenseProgress} />
            </div>
            <div className={styles.loadingContainer}></div>
        </a.div>
    ) : null;
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        zIndex: 9900,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'white'
    },
    loadingContainer: {
        position: 'absolute',
        width: '50',
        height: '10vh',
        textAlign: 'center',
        backgroundColor: 'blue',
        top: '50%',
        left: '50%',
        zIndex: 1000050,
        transform: 'translate(-50%,-50%)'
    },
    centered: {
        position: 'absolute',
        height: '33vh',
        width: '33vh',
        zIndex: 9999,
        backgroundColor: 'red',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    },
    car: {
        position: 'absolute',
        width: '9vw',
        left: '50vw',
        top: '45%',
        transform: 'translate(-50%,-50%)'
    },
    barContainer: {
        position: 'absolute',
        left: '50vw',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        backgroundColor: 'red'
    }
});
