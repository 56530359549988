import { useGLTF } from '@react-three/drei';
import React, { useRef } from 'react';
import { useAnimations } from '@react-three/drei';
import { useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useCar, useDebug, useLoading } from '../../stores';
import { Vector3 } from 'three';
import { base } from '../../constants';
export const Car = React.memo((props) => {
    const object = useGLTF(base + '/content/environment/Car.glb');
    const loader = useLoading();
    const { camera } = useThree();
    const { actions } = useAnimations(object.animations, object.scene);
    const car = useCar();
    const { speed } = useDebug();
    useEffect(() => {
        car.setAllAnimations(actions);
        Object.values(actions).forEach((anim) => {
            anim.clampWhenFinished = true;
            anim.paused = true;
            anim._mixer.timeScale = speed;
            anim.repetitions = 1;
        });
    }, [speed]);
    useEffect(() => {
        Object.values(actions).forEach((anim) => {
            anim.stop();
        });
        if (car.animation) actions[car.animation].play();
        actions[car.animation].paused = true;
    }, [car.animation]);
    useEffect(() => {
        if (car.shouldMove) actions[car.animation].paused = false;
    }, [car.shouldMove]);
    useEffect(() => {}, [car.animation]);
    useFrame(() => {
        loader.progress === loader.totalItems &&
            camera.position.set(
                ...Object.values(
                    actions.Anim_1._propertyBindings[0]?.binding?.resolvedProperty
                        .clone()
                        .add(new Vector3(135, 200, 205)) || new Vector3(0, 0, 0)
                )
            );
    });
    return (
        <>
            <primitive ref={props.car} object={object.scene} />
        </>
    );
});
