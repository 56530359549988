import { StyleSheet } from 'aphrodite';
import { useDebug } from './stores';

export const colorThemes = {
    blue: '#004A8F',
    darkerBlue: '#003A71',
    lighterBlue: '#005AC2',
    brightBlue: '#1D85FE'
};
export const textStyles = StyleSheet.create({
    heading48: {
        fontFamily: 'Fredoka One',
        // fontFamily:'',
        fontSize: '4.5vh',
        fontWeight: 'lighter',
        color: colorThemes.blue
        // '@media (max-width: 1300px)':{
        //     fontSize:42
        // },
        // '@media (max-width: 900px)':{
        //     fontSize:35
        // },
    },
    heading32: {
        color: colorThemes.blue,
        // fontFamily:'Roboto Bold',
        fontFamily: 'Fredoka One',

        fontSize: '4.15vh',
        '@media (max-width: 1300px)': {
            // fontSize:29
        },
        '@media (max-width: 900px)': {
            // fontSize:23
        }
        // fontWeight:'bold'
    },
    para18: {
        color: colorThemes.blue,
        fontFamily: 'Fredoka One',
        // fontFamily:'Roboto Light',
        fontWeight: 500,
        fontSize: '2.3vh',
        '@media (max-width: 1300px)': {
            // fontSize:18
        },
        '@media (max-width: 900px)': {
            // fontSize:14
        }
    },
    para16: {
        color: colorThemes.darkerBlue,
        fontSize: 18,
        fontFamily: 'Fredoka One',
        // fontFamily:'Roboto Light',
        '@media (max-width: 1300px)': {
            fontSize: 16
        },
        '@media (max-width: 900px)': {
            fontSize: 14
        }
    },
    para14: {
        color: colorThemes.darkerBlue,
        fontSize: '1.9vh',
        letterSpacing: '0.04vh',
        fontFamily: 'Fredoka One'
        // fontFamily:'Roboto Light',
        // '@media (max-width: 1300px)':{
        //     fontSize:14
        // },
        // '@media (max-width: 900px)':{
        //     fontSize:12
        // }
    },
    para12: {
        color: colorThemes.darkerBlue,
        fontSize: '1.6vh',
        fontFamily: 'Fredoka One'
        // fontFamily:'Roboto Light',
        // '@media (max-width: 1300px)':{
        //     fontSize:12
        // },
        // '@media (max-width: 900px)':{
        //     fontSize:10
        // }
    }
});
export const commonStyles = StyleSheet.create({
    fullscreen: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
});
const speed = useDebug.getState.speed;
export const springs = {
    // slow:config.slow,
    slow: {
        mass: 3.4,
        tension: 134,
        friction: 38,
        velocity: speed > 1 ? 4 : 0
    },
    wobbly: {
        // mass: 1.2,
        // tension: 107,
        // friction: 13,
        mass: 3.4,
        tension: 134,
        friction: 38,
        velocity: speed > 1 ? 4 : 0
    },
    cutscene: {
        mass: 8.9,
        tension: 10,
        friction: 11,
        velocity: speed > 1 ? 4 : 0
    }
};
export const _springs = { ...springs };
// export const commonStyles = StyleSheet.create({
//     button:
// })
