import { Vector3 } from 'three';
export const base = '.'

export const dev = false;
export const types = [
    '',
    'SavingsMax',
    "Women's Savings",
    'Regular Savings',
    'Digi Save Youth',
    'Senior Citizen Savings',
    // 'Family Banking',
    // 'Saving Farmers'
];

export const carStart = new Vector3(
    ...Object.values({ x: -2.8745046316954177, y: 2.3, z: -124.23909527172168 })
);
export const cameraStart = {
    rotation: new Vector3(-0.77, 0.45, 0.45),
    position: new Vector3(28, 50, -70)
};
export const starAnimationTime = 2000;
// up till pump
// [{"x":-31.728338208927887,"y":2.3,"z":357.9082531507081},{"x":-31.627786902486932,"y":2.3,"z":412.6235027899436},{"x":-25.39848844145052,"y":2.3,"z":480.97068588675614},{"x":1.7081996187401636,"y":2.3,"z":491.5423463841163},{"x":12.404949313370576,"y":2.3,"z":518.497019246613},{"x":38.85538814272408,"y":2.3,"z":528.6119943913079},{"x":42.35466737308164,"y":2.3,"z":567.2401110625293},{"x":41.884544043863556,"y":2.3,"z":603.0618312078922}]
export const videos = {
    preLaptop: {
        male: '/content/cutscenes/v2/HDFC_cutscene1_part1.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene1_part1_done.mp4'
    },
    laptop: {
        male: '/content/cutscenes/v2/HDFC_cutscene1_part2.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene1_part2_done.mp4'
    },
    dad: {
        male: '/content/cutscenes/v2/HDFC_cutscene1_part3.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene1_part3_done.mp4',
        duration: 16000
    },
    cafe: {
        male: '/content/cutscenes/v2/HDFC_cutscene2.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene2_done.mp4',
        duration: 8000
    },
    atmFinder: {
        male: '/content/cutscenes/v2/HDFC_cutscene3_part1.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene3_part1_done.mp4'
    },
    atmLoop: {
        male: '/content/cutscenes/v2/HDFC_cutscene3_part2.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene3_part2_done.mp4'
    },
    atmCash: {
        male: '/content/cutscenes/v2/HDFC_cutscene3_part3.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene3_part3_done.mp4'
    },
    atmCashNotActivated: {
        male: '/content/cutscenes/v2/HDFC_cutscene3_part3_option2.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene3_part3_option2_done.mp4'
    },
    fasttagBoard: {
        male: '/content/cutscenes/v2/HDFC_cutscene4_part1.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene4_part1_done.mp4'
    },
    fasttagContinue: {
        male: '/content/cutscenes/v2/HDFC_cutscene4_part2.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene4_part2_done.mp4'
    },
    bill: {
        male: '/content/cutscenes/v2/HDFC_cutscene5_part1.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene5_part1.mp4'
    },
    billPayed: {
        male: '/content/cutscenes/v2/HDFC_cutscene5_part2.mp4',
        female: '/content/cutscenes/female/withaudio/HDFC_cutscene5_part2_done.mp4'
    },

    skyDigiSaving: {
        male: '/content/cutscenes/v2/CutScene6/DigiSave.mp4',
        female: '/content/cutscenes/female/withaudio/CutScene6/DigiSave.mp4'
    },
    skyRegular: {
        male: '/content/cutscenes/v2/CutScene6/Regular.mp4',
        female: '/content/cutscenes/female/withaudio/CutScene6/Regular.mp4'
    },
    skySavingMax: {
        male: '/content/cutscenes/v2/CutScene6/SavingsMax.mp4',
        female: '/content/cutscenes/female/withaudio/CutScene6/SavingsMax.mp4'
    },
    skySeniorCitizen: {
        male: '/content/cutscenes/v2/CutScene6/SeniorCitizens.mp4',
        female: '/content/cutscenes/female/withaudio/CutScene6/SeniorCitizens.mp4'
    },
    skyWomen: {
        male: '/content/cutscenes/v2/CutScene6/Womens.mp4',
        female: '/content/cutscenes/female/withaudio/CutScene6/Womens.mp4'
    }
};

export const thoughtBubble = [
    {
        male: '/content/bubbles/avatar_panel_1.png',
        female: '/content/bubbles/hema/avatar_panel_1.png'
    },
    {
        male: '/content/bubbles/avatar_panel_2.png',
        female: '/content/bubbles/hema/avatar_panel_2.png'
    },
    {
        male: '/content/bubbles/avatar_panel_3.png',
        female: '/content/bubbles/hema/avatar_panel_3.png'
    },
    {
        male: '/content/bubbles/avatar_panel_4.png',
        female: '/content/bubbles/hema/avatar_panel_4.png'
    },
    {
        male: '/content/bubbles/avatar_panel_5.png',
        female: '/content/bubbles/hema/avatar_panel_5.png'
    },
    {
        male: '/content/bubbles/avatar_panel_6.png',
        female: '/content/bubbles/hema/avatar_panel_6.png'
    },
    {
        male: '/content/bubbles/avatar_panel_1.png',
        female: '/content/bubbles/hema/avatar_panel_1.png'
    },
    {
        male: '/content/bubbles/activate.png',
        female: '/content/bubbles/hema/activate.png'
    },
    {
        male: '/content/bubbles/activated.png',
        female: '/content/bubbles/hema/activated.png'
    }
];

export const meshes = [
    '/content/environment/v7/HDFC_Set_1.glb',
    '/content/environment/v7/HDFC_Set_2.glb',
    '/content/environment/v7/HDFC_Set_3.glb',
    '/content/environment/v7/HDFC_Set_4.glb',
    '/content/environment/v7/HDFC_Set_5.glb',
    '/content/environment/v7/HDFC_Set_6.glb',
    '/content/environment/v7/HDFC_Set_7.glb'
];

export const starUrls = [
    base + '/content/stars/perfect_opener.png',
    base + '/content/stars/KYC Champion.png',
    base + '/content/stars/Eagle_eye.png',
    base + '/content/stars/super_swiper.png',
    base + '/content/stars/Speed_Racer.png',
    base + '/content/stars/Pitstop_pro.png',
    base + '/content/stars/super_saver.png',
    base + '/content/stars/recharge_rockstar.png',
    base + '/content/stars/individual-star.png'
];

export const nostar = base + '/content/stars/starno.png';
export const starUrlObj = {
    open: '/content/stars/perfect_opener.png',
    kyc: '/content/stars/KYC Champion.png',
    atmFinder: '/content/stars/Eagle_eye.png',
    atmCash: '/content/stars/super_swiper.png',
    recharge: '/content/stars/recharge_rockstar.png',
    petrol: '/content/stars/Pitstop_pro.png',
    toll: '/content/stars/Speed_Racer.png',
    drivein: '/content/stars/super_saver.png',
    empty: '/content/stars/individual-star.png'
};

Object.values(starUrls).forEach((url) => {
    let tmp = new Image();
    tmp.src = url;
});

export const maleHead = '/content/malehead.png';

export const sounds = {
    beep: '/content/sounds/beep.mp3'
};

export const introductionImages = {
    homepage: base + '/content/introduction/HDFC_Title.jpg',
    male: base + '/content/introduction/male_avatar.png',
    female: base + '/content/introduction/female-avatar.png'
};
