import './App.css';
import {useRef, useEffect} from 'react'
import { RouterChecker } from './components/core/RouteChecker';
import { Canvas } from '@react-three/fiber';
import { PagesContainer } from './components/selection/PagesContainer';
import { Overlay } from './components/core/Overlay';
import { Environment } from './components/environment/Environment';
import { useSteps } from './stores';
import { ExperienceUI } from './components/UI/ExperienceUI';
import { Loading } from './components/UI/Loading';
import PortraitError from './PortraitError/PortraitError';
import { Lights } from './components/core/Lights';
import { PreloadStars } from './components/UI/stars/PreloadStars';
import { MuteButton } from './components/asssets/MuteUnmute';
import { dev } from './constants';

function App() {
    const steps = useSteps();
    const ref = useRef()
    // useEffect(() => {
    //     window.scroll(0, ref.current.scrollHeight)
    // })
    return (
        <div ref={ref} className="App">
            <Canvas shadows>
                <Lights />
                <Environment />
            </Canvas>
                <Overlay />
                <RouterChecker />
                {steps.step && <Loading />}
                <PortraitError />
                <PagesContainer />
                {/* <Leva hidden={!dev} /> */}
                <PreloadStars />
                <ExperienceUI />
                <MuteButton />

            
        </div>
    );
}

export default App;
