import { useFrame, useThree } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';
import { Vector3 } from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { useCar, useLoading, useSteps } from '../../stores';
import * as THREE from 'three';
import { lerp } from 'three/src/math/MathUtils';
import { Scan } from './Scan';

const Tweener = ({ object, to, time, isCar, lastCamera, cameraTo }) => {
    const { camera, invalidate } = useThree();
    const carState = useCar();
    const steps = useSteps();

    useEffect(() => {
        carState.teleport &&
            object.position.set(...Object.values(carState.teleport));
    }, [carState.teleport]);

    useEffect(() => {
        let tween;
        if (!isCar || (to && object && to.slice(-1) !== object.position)) {
            const catmull = new THREE.CatmullRomCurve3([
                object.position.clone(),
                ...to.map((pos) => new Vector3(pos.x, pos.y, pos.z))
            ]);

            tween = new TWEEN.Tween({ x: 0 })
                .to({ x: 1 }, time)
                .onUpdate((state) => {
                    let point = catmull
                        .getPoint(state.x, object.position)
                        .clone();
                    let direction = catmull.getTangent(state.x).clone();
                    point.addScaledVector(direction, 50);
                    object.lookAt(point);
                    catmull.getPoint(state.x, object.position);
                    const cam = catmull.getPoint(state.x).clone();
                    camera.position.set(cam.x + 135, cam.y + 200, cam.z + 205);
                    if (lastCamera) {
                        const zoom = lerp(
                            lastCamera.zoom,
                            cameraTo.zoom,
                            state.x
                        );
                        camera.zoom = zoom;
                    }
                });
            tween.start();
        }
    }, [to]);

    useFrame(() => {
        camera.updateProjectionMatrix();
        TWEEN.update();
        invalidate();
        if (!steps.cutscene) invalidate();
    });
    return null;
};

export const TweenManager = (props) => {
    const loading = useLoading();
    const firstTime = useRef(true);
    return (
        <>
            {firstTime.current &&
                loading.progress / loading.totalItems < 1 &&
                props.car.current && <Scan />}
        </>
    );
};
