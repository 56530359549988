import { OrthographicCamera } from '@react-three/drei';
import React, { useState, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { cameraStart } from '../../constants';

export const Camera = ({ controls }) => {
    const [orbit, setOrbit] = useState(true);
    const { camera } = useThree();
    const [height, setHeight] = useState(window.innerHeight)
    const [width, setWidth] = useState(window.innerWidth)
    const [windowState, setWindow] = useState({
        height: 768*2,
        width: 1600*2
    });
    
    let offset = 12
    const cameraWidth = 768;

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'x') setOrbit(false);
        });
        window.addEventListener('keyup', (e) => {
            if (e.key === 'x') setOrbit(true);
        });
        window.addEventListener('resize',()=>{
            setHeight(window.innerHeight)
        })
    }, []);
    useFrame(() => {
        camera.updateProjectionMatrix();
    });
    
    return (
        <>
            <OrthographicCamera
                makeDefault
                rotation={Object.values(cameraStart.rotation)}
                position={Object.values(cameraStart.position)}
                zoom={height<600?6.8:window.innerWidth>1900?16:12}
                left={-window.innerWidth / 2 - offset}
                right={window.innerWidth / 2 + offset}
                top={window.innerHeight / 2 - offset}
                bottom={-window.innerHeight / 2 + offset}
                near={0}
                far={400}
            />
        </>
    );
};
