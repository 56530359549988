import create from 'zustand';

export const useParamsChanger = create((set) => ({
    params: '', // [[][]]
    setParams: (params) => set((state) => (state.params = params))
}));

export const useSteps = create((set) => ({
    startMoving: false,
    step: 0,
    cutscene: false,
    setStep: (step) => set((state) => ({ startMoving: false, step: step })),
    setStartMoving: (bol) => set((state) => (state.startMoving = bol)),
    setCutscene: (bol) => set((state) => (state.cutscene = bol))
}));
export const useStars = create((set) => ({
    stars: 0,
    text: '',
    isLastScene: false,
    setIsLastScene: () => set((state) => (state.isLastScene = true)),
    options: ['yes', 'yes', 'no', 'no'],
    options: [],
    add: (text) =>
        set((state) => {
            state.stars += 1;
            state.text = text;
            state.options = [...state.options, text];
        }),
    reset: () =>
        set((state) => {
            state.stars = 0;
            state.options = [];
        })
}));

export const useDebug = create((set) => ({
    speed: 1,
    setSpeed: (sp) => set((state) => (state.speed = sp))
}));

export const usePreferences = create((set) => ({
    preferences: {},
    setType: (type) => set((state) => (state.preferences.type = type)),
    setAvatar: (avatar) => set((state) => (state.avatar = avatar)),
    setPreferences: (pref) => set((state) => (state.preferences = {...state.preferences, ...pref }))
}));

export const useCar = create((set) => ({
    animation: 'Anim_1',
    shouldMove: false,
    animations: {},
    setMoving: (tween) => set((state) => (state.isMoving = tween)),
    setCar: (car) => set((state) => (state.car = car)),
    setAllAnimations: (anim) => set((state) => (state.animations = anim)),
    setAnimation: (anim, shouldAnimate = false) =>
        set((state) => {
            state.shouldAnimate = shouldAnimate;
            state.animation = anim;
        }),
    start: () => set((state) => (state.shouldMove = true)),
    setTeleport: (position) => set((state) => (state.teleport = position))
}));
export const usePage = create((set) => {});
export const useName = create((set) => ({
    name: '',
    setName: (name) => set((state) => (state.name = name))
}));
export const useIdleCar = create((set) => ({
    shouldMove: false,
    setShouldMove: (bol) => set((state) => (state.shouldMove = bol))
}));
export const useLoading = create((set) => ({
    progress: 0,
    totalItems: 3, //7 for meshes + 1 is for scan //!! changed from 2 to 3
    suspenseProgress: 0,
    setSuspenseProgress: (pro) =>
        set((state) => (state.suspenseProgress = pro)),
    doneLoading: false,
    reportDone: () => set((state) => (state.progress += 1)),
    reset: () => set((state) => (state.progress = 0)),
    setLoading: (progress) => set((state) => (state.progress = progress))
}));
export const useSFX = create((set) => ({
    name: '',
    mute: false,
    driving: false,
    startCar: false,
    notification: false,
    hint: false,
    ambient: false,
    setMuted: (bol) => set((state) => (state.mute = bol)),
    setName: (name) =>
        set((state) => {
            state.name = name;
            setTimeout(() => set((state) => (state.name = '')), [300]);
        }),
    setDriving: (time) => set((state) => (state.driving = time)),
    setStartCar: (start) =>
        set((state) => {
            state.startCar = true;
            setTimeout(() => {
                set((state) => (state.startCar = false));
            }, [2000]);
        }),
    playNotification: () =>
        set((state) => {
            state.notification = true;
            setTimeout(() => {
                set((state) => (state.notification = false));
            }, [1500]);
        }),
    playHint: () =>
        set((state) => {
            state.hint = true;
            setTimeout(() => {
                set((state) => (state.hint = false));
            }, [1500]);
        }),
    setAmbient: () =>
        set((state) => {
            set((state) => (state.ambient = true));
            setTimeout(() => {
                set((state) => (state.ambient = false));
            }, [2000]);
        })
}));
