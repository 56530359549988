import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Logo } from '../asssets/Logo';
import { animated as a } from 'react-spring';

export const Overlay = (props) => {
    return (
        <a.div className={css(styles.container)}>
            <Logo className={css(styles.logo)} />
        </a.div>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        height: '16.1%',
        top:0,
        left:0,
        zIndex: 10000,
        pointerEvents: 'none'
    },
    logo: {
        position: 'absolute',
        left: '2vw',
        top: '2.4vh',
        // top: '42%',
        // transform: 'translateY(-50%)',
        height: '42%',
        '@media (max-width: 900px)': {
            height: '80%'
        }
    }
});
