import React, { Suspense, useEffect, useRef } from 'react';
import { useLoading, useSteps } from '../../stores';
import { Camera } from '../core/Camera';
import Skybox from '../core/Skybox';
import { TweenManager } from '../core/Tweener';
import { Car } from './Car';
import { Floor } from './Floor';
import { AllMeshes } from './Map';
import { useProgress } from '@react-three/drei';
import { IdleCar } from './IdleCar';
const Loader = () => {
    const loader = useLoading();
    const { progress, ...bakki } = useProgress();
    const count = useRef(0);

    useEffect(() => {
        count.current += 1;
        loader.setSuspenseProgress(progress);
    }, [bakki.loaded]);
    return null;
};

export const Environment = (props) => {
    const car = useRef();
    const steps = useSteps();
    const controls = useRef();
    return (
        steps.step &&
        true && (
            <>
                <Suspense fallback={<Loader />}>
                    {true && <AllMeshes />}
                </Suspense>
                <Suspense fallback={null}>
                    <Camera controls={controls} />
                    <Skybox name={'skybox.hdr'} />
                    <Floor />
                    <Car car={car} />
                    <IdleCar />
                    {<TweenManager controls={controls} car={car} />}
                </Suspense>
            </>
        )
    );
};
