import { css, StyleSheet } from 'aphrodite';
import { useState } from 'react';
import CouponLogo from './CouponLogo';
import { animated as a } from 'react-spring';
const Coupons = ({ getYesCount, spring }) => {
    const couponsInfo = [
        {
            url: 'lifestyle.png',
            alt: 'Lifestyle',
            link: 'https://www.lifestylestores.com/in/en/'
        },
        {
            url: 'aldo.png',
            alt: 'Aldo',
            link: ''
        },
        {
            url: 'pizzahut.png',
            alt: 'Pizzahut',
            link: 'https://vms.insenseanalytics.com/campaign/291'
        },
        {
            url: 'zoomcar.png',
            alt: 'Zoomcar',
            link: 'https://madn.io/Zoomcar'
        },
        {
            url: 'ease_my_trip.png',
            alt: 'Ease My Trip',
            link: 'https://www.easemytrip.com/'
        },
        {
            url: 'boat.png',
            alt: 'Boat',
            link: 'https://www.boat-lifestyle.com/'
        },
        {
            url: 'apollo_pharmacy.png',
            alt: 'Apollo Pharmacy',
            link: ''
        },
        {
            url: 'upgrad.png',
            alt: 'upGrad',
            link: 'https://programs.upgrad.com/hdfcbank'
        }
    ];
    const [selectedCoupon, setselectedCoupon] = useState(0);

    return (
        <a.div style={spring} class={css(styles.coupons)}>
            <div>
                <h2 className={css(styles.heading)}>Select the Offer</h2>
                <div className={css(styles['coupon-logos-container'])}>
                    {couponsInfo.map((coupon, logoIndex) => (
                        <CouponLogo
                            logoUrl={`coupons-logos/${coupon.url}`}
                            alt={`${coupon.alt} Logo`}
                            setselectedCoupon={setselectedCoupon}
                            selectedCoupon={selectedCoupon}
                            logoIndex={logoIndex}
                            getYesCount={getYesCount}
                        />
                    ))}
                </div>
            </div>
            <div className={css(styles['coupon-info'])}>
                <h2 className={css(styles.heading)}>Avail the offer</h2>

                {couponsInfo[selectedCoupon].link ? (
                    <a
                        href={couponsInfo[selectedCoupon].link}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: 'pointer' }}
                    >
                        <img
                            height={'100%'}
                            src={`coupons-info/${couponsInfo[selectedCoupon].url}`}
                            alt={`${couponsInfo[selectedCoupon].alt} Coupon Info`}
                        />
                    </a>
                ) : (
                    <img
                        height={'100%'}
                        src={`coupons-info/${couponsInfo[selectedCoupon].url}`}
                        alt={`${couponsInfo[selectedCoupon].alt} Coupon Info`}
                    />
                )}
            </div>
        </a.div>
    );
};
export default Coupons;

const styles = StyleSheet.create({
    coupons: {
        // display: 'flex',
        display: 'none',
        gap: '0.5vw',
        position: 'absolute',
        zIndex: 9999,
        bottom: '2vh',
        padding: '0px 20px',
        width: '85%',
        justifyContent: 'center',
        left: '49vw',
        transform: 'translateX(-50%)'
    },
    'coupon-info': {
        width: '40%',
        height: '27vh'
    },
    'coupon-logos-container': {
        display: 'flex',
        gap: 10,
        flexWrap: 'wrap',
        '@media screen and (max-height: 635px)': {
            flex: '1 1 50%'
        }
    },
    heading: {
        color: '#fff',
        fontSize: 'clamp(1rem, 5vh, 1.5rem)',
        fontFamily: 'Fredoka One'
    }
});
