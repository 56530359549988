import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { animated as a } from '@react-spring/web';
const Banner = () => {
    return (
        <div className={css(styles.banner)}>
            <img
                style={{ float: 'left' }}
                src="/content/kfclogo.png"
                height="100%"
                alt="kfclogo"
            />
            <p className={css(styles.p)}>
                KFC100: Get Rs 50% off upto Rs 100 on your next meal above Rs
                250.*T&C apply
            </p>
        </div>
    );
};
export const KFCPromo = (props) => {
    return (
        <a.div {...props} className={css(styles.container)}>
            <Banner />
            <Banner />
        </a.div>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: '-24vh',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 99999999
    },
    banner: {
        width: '60vw',
        marginTop: '0.5vh',
        display: 'flex',
        height: '12vh',
        border: '0.2vh white solid'
    },
    p: {
        fontSize: '2vh',
        fontWeight: 400,
        color: 'white',
        margin: 0,
        marginLeft: '2vw',
        padding: 0,
        // marginTop:'6vh',
        lineHeight: '12vh'
        // transform:'translateY(-50%)'
    }
});
