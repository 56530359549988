import { useThree, useFrame } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';
import { CineonToneMapping } from 'three';

export const Lights = () => {
    const light = useRef();
    const { gl } = useThree();

    // const {
    //     nb,
    //     camposition,
    //     pointInt,
    //     distance,
    //     intensity,
    //     decay,
    //     ambientInt,
    //     ...controls
    // } = useControls({
    //     // sr: {
    //     //     value: -0.00004,
    //     //     step: 0.01
    //     // },
    //     // nb: {
    //     //     value: 1.35,
    //     //     step: 0.01
    //     // },
    //     // pointInt: {
    //     //     value: 1.25,
    //     //     step: 0.05
    //     // },
    //     // distance: {
    //     //     value: 2000,
    //     //     step: 1
    //     // },
    //     // ambientInt: {
    //     //     value: 0.2,
    //     //     step: 0.05
    //     // },
    //     // X: {
    //     //     value: 9,
    //     //     min: 1,
    //     //     max: 20,
    //     //     step: 1
    //     // },
    //     // near: {
    //     //     value: 1.5,
    //     //     step: 0.05
    //     // },
    //     // far: {
    //     //     value: 1000,
    //     //     step: 0.05
    //     // },
    //     // top: {
    //     //     value: window.innerHeight / 2,
    //     //     step: 2
    //     // },
    //     // bottom: {
    //     //     value: -window.innerHeight / 2,
    //     //     step: 2
    //     // },
    //     // left: {
    //     //     value: -window.innerWidth / 2,
    //     //     step: 2
    //     // },
    //     // right: {
    //     //     value: window.innerWidth / 2,
    //     //     step: 2
    //     // },
    //     // camposition: {
    //     //     value: { x: 0, y: 0, z: 0 },
    //     //     step: 1
    //     // }
    // });
    useEffect(() => {
        gl.toneMapping = CineonToneMapping;
        gl.toneMappingExposure = 0.9;
    }, []);
    useEffect(() => {
        //     // light.current.shadow.camera.near=near
        //     if(light.current){
        //         // light.current.shadow.mapSize.set( 2**controls.X, 2**controls.X );
        light.current.shadow.mapSize.set(Math.pow(2, 12), Math.pow(2, 12));
        //         light.current.shadow.camera.near =controls.near // default
        //         light.current.shadow.camera.far = controls.far;
        //         light.current.shadow.camera.left = controls.left
        //         light.current.shadow.camera.right = controls.right
        //         // light.current.shadow.camera.bias = controls.sr
        //         // light.current.shadow.bias = controls.sr
        //         // light.current.shadow.bias = -0.00004
        //         // light.current.shadow.camera.top = window.innerHeight/2
        //         // light.current.shadow.camera.bottom = -window.innerHeight/2
        //         // light.current.shadow.camera.bottom = -window.innerHeight/2
        //         light.current.shadow.camera.top = controls.top
        //         light.current.shadow.camera.bottom = controls.bottom
        //         console.log(light.current.shadow)
        // }
        // }, [controls.X, controls.far, controls.near, controls]);
    }, []);
    useFrame(() => {
        // transform.current && console.log(Object.values(transform.current.positionStart))
        // transform.current && console.log(Object.values(transform.current))
        // console.log(light.current?.shadow.camera)
        // light.current.updateMatrixWorld()
        // helper.current.update()
    });
    return (
        <>
            <directionalLight
                ref={light}
                color={'#fff4e2'}
                position={[-15, 100, -15]}
                castShadow
                shadow-normalBias={1.01}
                intensity={0.8}
                // intensity={0.9}
                // shadow-normalBias={-0.08}
                shadow-camera-near={0}
                shadow-camera-far={1000}
                shadow-camera-left={-768}
                shadow-camera-right={768}
                shadow-camera-top={377}
                shadow-camera-bottom={-377}
            />

            <ambientLight intensity={0.9} color={'0672fe'} />
        </>
    );
};
