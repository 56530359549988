import { useSpring } from '@react-spring/core';
import React, { useEffect } from 'react';
import {
    useCar,
    useDebug,
    useIdleCar,
    useName,
    usePreferences,
    useSFX,
    useStars,
    useSteps
} from '../../stores';
import { StyleSheet } from 'aphrodite';
import { TextButtonBox } from './OptionsButton';
import { animated as a } from 'react-spring';
import { useState } from 'react';
import { Cutscene } from './Cutscene';
import { videos } from '../../constants';
import { springs } from '../../commonStyles';
import { ThoughtBubble } from './Thought';
import { NotificationFlex } from './NotificationFlex';
import { sendEvent } from '../selection/Analytics';

const phases = {
    atmScene: 0,
    sawAtm: 7,
    sawAtmTextbox: 9,
    atmLoop: 10,
    atmCash: 12,
    noCash: 12.5,
    yesCash: 12.7,
    atmCashTextbox: 14,
    fasttagBoard: 15,
    fasttagBoardTextbox: 20,
    fasttagContinue: 22,
    moveToPump: 25,
    moveToToll: 30
};
export const Step2 = () => {
    const steps = useSteps();
    const [activated, setActivated] = useState(false);
    const car = useCar();
    const idleCar = useIdleCar();
    const name = useName().name;
    const { speed } = useDebug();
    const stars = useStars();
    const [pickedCash, setPickedCash] = useState(true);
    const [phase, setPhase] = useState(phases.atmScene);
    // const [hideContinue,setHideContinue] = useState()
    const {
        preferences: { avatar }
    } = usePreferences();

    const [analyticsActions, setAnalyticsActions] = useState('');
    const [choice, setChoices] = useState(null);

    useEffect(() => {
        switch (analyticsActions) {
            case 'useATMLocator':
                sendEvent('eagle_eye', choice);
                break;

            case 'useBankCard':
                sendEvent('super_swiper', choice);
                break;

            case 'useFastTag':
                sendEvent('speed_Racer', choice);
                break;
            default:
                break;
        }
    }, [analyticsActions]);

    const atmFinderSceneSpring = useSpring({
        from: { opacity: 0 },
        opacity:
            phase === phases.atmScene ||
            phase === phases.sawAtm ||
            phase === phases.sawAtmTextbox || 
            phase === phases.atmLoop
                ? 1
                : 0,
        config: springs.cutscene
    });
    const atmLoopSpring = useSpring({
        opacity: phase === phases.atmLoop ? 1 : 0,
        config: springs.cutscene
    });
    const atmCashSpring = useSpring({
        from: { opacity: 0 },
        opacity:
            phase === phases.atmCash ||
            phase === phases.noCash ||
            phase === phases.yesCash ||
            phase === phases.atmCashTextbox
                ? 1
                : 0,
        config: springs.cutscene
    });
    const fasttagBoardSpring = useSpring({
        opacity:
            (phase === phases.fasttagBoard ||
                phase === phases.fasttagBoardTextbox ||
                phase === phases.fasttagContinue) &&
            pickedCash &&
            steps.step === 2
                ? 1
                : 0,
        config: springs.cutscene
    });
    const fasttagContinueScene = useSpring({
        opacity:
            phase === phases.fasttagContinue && !pickedCash && steps.step == 2
                ? 1
                : 0,
        config: springs.cutscene
    });

    const sfx = useSFX();

    return (
        <>
            {phase >= phases.atmScene && (
                <>
                    <Cutscene
                        video={videos.atmFinder}
                        style={{ ...atmFinderSceneSpring }}
                        timeBasedFunction={[
                            [18500, () => setPhase(phases.sawAtm)],
                            [20000, () => stars.add('yes')]
                        ]}
                        onEnd={() => {
                            setPhase(phases.sawAtmTextbox);
                            setAnalyticsActions('useATMLocator');
                            setChoices(true);
                        }}
                        seemless
                    />
                </>
            )}
            {
                <NotificationFlex>
                    <ThoughtBubble
                        idx={1}
                        delay={0}
                        condition={() =>
                            phase === phases.sawAtm ||
                            phase === phases.sawAtmTextbox
                        }
                    />
                </NotificationFlex>
            }

            {
                <TextButtonBox
                    buttonText="Go to ATM"
                    heading="Wow, that was convenient!"
                    text="Time to withdraw cash then."
                    condition={phase === phases.sawAtmTextbox}
                    handleClick={() => setPhase(phases.atmLoop)}
                    topLeft
                />
            }
            {/* {phase >= phases.atmLoop && (
                <Cutscene
                    video={videos.atmLoop}
                    loop={phase === phases.atmLoop}
                    style={{ ...atmLoopSpring }}
                    seemless
                />
            )} */}
            {
                <TextButtonBox
                    heading="How will you withdraw cash?"
                    buttonOptions={[
                        'Activate HDFC Bank Debit Card',
                        'Use Other Bank Account'
                    ]}
                    topLeft
                    condition={phase === phases.atmLoop}
                    delay={phase === phases.atmLoop ? 2000 / speed : 0}
                    buttonText={
                        'Activate HDFC Bank Debit Card,Use Other Bank Account'
                    }
                    clickOptions={[
                        () => setActivated(true),
                        () => {}
                    ]}
                    handleClick={() =>
                        setTimeout(() => setPhase(phases.atmCash), [0])
                    }
                />
            }
            {phase >= phases.atmCash && (
                <Cutscene
                    video={
                        activated ? videos.atmCash : videos.atmCashNotActivated
                    }
                    style={{ ...atmCashSpring }}
                    onEnd={() => setPhase(phases.noCash)}
                    seemless
                />
            )}
            {
                <TextButtonBox
                    topLeft
                    condition={phase === phases.noCash}
                    heading={
                        !activated
                            ? 'Cash Withdrawn!'
                            : "You've unlocked a lifetime of ease!"
                    }
                    buttonText="Next"
                    text={
                        !activated
                            ? 'You got cash but missed out on the ease of using the HDFC Bank Debit Card.'
                            : `Activating ${
                                  avatar === 1 ? 'Hitesh' : 'Hema'
                              }'s HDFC Bank Debit Card was a smart move - you'll see the benefits soon.`
                    }
                    handleClick={() => setPhase(phases.atmCashTextbox)}
                />
            }
            {
                <TextButtonBox
                    heading={`What about you, ${name}?`}
                    text="Have you activated your HDFC Bank Debit Card?"
                    buttonOptions={['Yes', 'No']}
                    topLeft
                    condition={phase === phases.atmCashTextbox}
                    clickOptions={[
                        () => {
                            stars.add('yes');
                            setPhase(phases.fasttagBoard);
                            setChoices(true);
                        },
                        () => {
                            stars.add('no');
                            setPhase(phases.yesCash);
                            setChoices(false);
                        }
                    ]}
                    handleClick={() => {
                        setAnalyticsActions('useBankCard');
                    }}
                />
            }
            {
                <TextButtonBox
                    topLeft
                    buttonText="Next"
                    condition={phase === phases.yesCash}
                    heading={`Make sure you do it immediately, ${name}!`}
                    text={`Activating the HDFC Bank Debit Card will give you access to exclusive discounts at restaurants, petrol pumps and more.`}
                    handleClick={() => setPhase(phases.fasttagBoard)}
                />
            }
            {
                <NotificationFlex>
                    <ThoughtBubble //activated
                        condition={() =>
                            (phase === phases.atmCashTextbox ||
                                phase === phases.noCash) &&
                            activated
                        }
                        delay={0}
                        idx={8}
                    />
                </NotificationFlex>
            }

            {phase >= phases.fasttagBoard && (
                <Cutscene
                    video={videos.fasttagBoard}
                    style={{ ...fasttagBoardSpring }}
                    seemless
                    onEnd={() =>
                        setTimeout(
                            () => setPhase(phases.fasttagBoardTextbox),
                            [0]
                        )
                    }
                    // onEndTime={{do:()=>setPhase(phases.fasttagBoardTextbox),time:3.0}}
                />
            )}
            {
                <NotificationFlex>
                    <ThoughtBubble
                        delay={3000 / speed}
                        idx={2}
                        time={1114000}
                        condition={() =>
                            (phase === phases.fasttagBoard ||
                                phase === phases.fasttagBoardTextbox) &&
                            steps.step === 2
                        }
                        text="Is that a toll? I should recharge my FASTag to zoom past it."
                    />
                </NotificationFlex>
            }
            {/* {
                <Hints
                    heading="Toll Booth Up Ahead"
                    noShow
                    hints="Buy FASTag in just 3 simple steps & enjoy cashless toll payments online"
                    style={{
                        ...fasttagBoardSpring,
                        pointerEvents: phase===phases.fasttagBoard || phase===phases.fasttagBoardTextbox
                            ? 'auto'
                            : 'none'
                    }}
                /> 
            } */}

            {
                <TextButtonBox
                    heading="What will you do?"
                    condition={
                        phase === phases.fasttagBoardTextbox && steps.step === 2
                    }
                    text="Hint: Buy FASTag in just 3 simple steps & enjoy cashless toll payments online."
                    buttonOptions={[
                        'Recharge FASTag using HDFC Bank NetBanking',
                        "We're Late, Just Use Cash"
                    ]}
                    clickOptions={[
                        () => {
                            stars.add('yes');
                            setPickedCash(false);
                            car.setAnimation('Anim_2');
                            setChoices(true);
                        },
                        () => {
                            stars.add('no');
                            car.setAnimation('Anim_2');
                            setChoices(false);
                        }
                    ]}
                    topLeft
                    handleClick={() => {
                        setPhase(phases.fasttagContinue);
                        setAnalyticsActions('useFastTag');
                    }}
                />
            }
            {phase >= phases.fasttagContinue && (
                <Cutscene
                    video={videos.fasttagContinue}
                    style={fasttagContinueScene}
                    skip={pickedCash}
                />
            )}
            {
                <TextButtonBox
                    heading={
                        !(stars.options[4] == 'yes')
                            ? 'You picked cash and will have to wait in line'
                            : 'Well done!'
                    }
                    text={
                        !(stars.options[4] == 'yes')
                            ? 'By recharging FASTag using HDFC Bank NetBanking, you can zip past the toll without stopping and paying the double(2X) the cash'
                            : 'By recharging FASTag using HDFC Bank NetBanking, you can zip past the toll without stopping and paying the double(2X) the cash'
                    }
                    // heading={!stars.options.includes(starUrls.toll)?"You picked cash and will have to wait in line":"Well done!"}
                    // text={!stars.options.includes(starUrls.toll)?"By recharging FASTag using HDFC Bank, you can save up to 2.5% on recharge and zip past without stopping":"By recharging FASTag using HDFC Bank, you can save 2.5% on recharge and zip past without stopping"}
                    condition={phase === phases.fasttagContinue}
                    delay={
                        phase === phases.fasttagContinue && !pickedCash
                            ? 5000 / speed
                            : 0
                    }
                    buttonText="Continue"
                    topLeft
                    handleClick={() => {
                        setPhase(phases.moveToPump);
                        sfx.setStartCar();
                        if (stars.options[4] == 'yes') {
                            idleCar.setShouldMove(true);
                            setTimeout(() => {
                                sfx.setDriving(true);
                                car.animations.Anim_2.paused = false;
                            }, [2500 / speed]);
                            setPhase(phases.moveToPump);
                            setTimeout(() => {
                                sfx.setDriving(false);
                                steps.setStep(3);

                                // car.setAnimation('Anim_3')
                            }, [
                                (car.animations.Anim_2._clip.duration * 1000 +
                                    3500) /
                                    speed
                            ]);
                        } else {
                            sfx.setStartCar();
                            setPhase(phases.moveToPump);
                            setTimeout(() => {
                                car.animations.Anim_2.paused = false;
                                sfx.setDriving(true);
                            }, [2500 / speed]);
                            setTimeout(() => {
                                sfx.setDriving(false);
                                setTimeout(() => {
                                    sfx.setDriving(false);
                                    setTimeout(() => {
                                        sfx.setDriving(true);
                                    }, [2000]);
                                    idleCar.setShouldMove(true);
                                }, [2000 / speed]);
                                setTimeout(() => {
                                    steps.setStep(3);
                                }, [4000 / speed]);
                            }, [
                                (car.animations.Anim_2._clip.duration * 1000 +
                                    3500) /
                                    speed
                            ]);
                        }
                    }}
                />
            }
        </>
    );
};
