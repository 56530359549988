import React, { useRef } from 'react';
import styles from './PortraitErrorStyles';
import { css } from 'aphrodite';
import ScreenRotate from './portraitGIF/ScreenRotate.json';
import Lottie from 'react-lottie';
import { Logo } from '../components/asssets/Logo';
export default function PortraitError() {
    const animation = useRef();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: ScreenRotate,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            {
                <div className={css(styles.ErrorContainer)}>
                    <div className={css(styles.logo)}>
                        <Logo />
                    </div>
                    <div className={css(styles.animationContainer)}>
                        <Lottie
                            ref={animation}
                            options={defaultOptions}
                            height={100}
                            width={100}
                        />
                    </div>
                    <div className={css(styles.errorText)}>
                        Please rotate your screen to start the experience
                    </div>
                </div>
            }
        </>
    );
}
