import React, { useEffect } from 'react';
import { useSFX, useStars, useSteps } from '../../stores';
import { useAudio } from 'react-use';
import { isIOS, isSafari } from 'react-device-detect';
import { base } from '../../constants';
export const sfxs = {
    ambient: base + '/content/SFX/ambient.wav',
    driving: base + '/content/SFX/driving.wav',
    avatarReaction: base + '/content/SFX/sfx_3_Avatar_reacting.wav',
    tollGate: base + '/content/SFX/sfx_4_toll-gate.wav',
    refuel: base + '/content/SFX/sfx_5_refuel-tank.wav',
    button: base + '/content/SFX/button.wav',
    star: base + '/content/SFX/stars.wav',
    hint: base + '/content/SFX/sfx_7_Hint icon.wav',
    start: base + '/content/SFX/start.wav',
    notification: base + '/content/SFX/sfx_2_Message_pop up.wav'
};
export const SFX = () => {
    const [button, ____, buttonControls] = useAudio({ src: sfxs.button });
    const step = useSteps();
    const stars = useStars();
    const sfx = useSFX();
    const [starSound, starState, starControls] = useAudio({ src: sfxs.star });
    const [ambientSound, state, ambientControls] = useAudio({
        src: '/content/SFX/ambient.wav',
        id: 'ambientAudio',
        preload: 'auto',
        autoPlay: isIOS || isSafari? false : true,
        loop: true
    });
    const [driving, _, drivingControls] = useAudio({
        src: sfxs.driving,
        loop: true
    });
    const [carStart, _x, carStartControls] = useAudio({ src: sfxs.start });
    const [notification, _y, notificationControls] = useAudio({
        src: sfxs.notification
    });
    const [hint, _h, hintControls] = useAudio({ src: sfxs.hint });
    const [petrol, __, petrolControls] = useAudio({ src: sfxs.refuel });

    useEffect(() => {
        ambientControls.volume(0.3);
        starControls.volume(0.4);
        drivingControls.volume(0.9);
    }, []);
    useEffect(() => {
        if (sfx.name === 'petrol') petrolControls.play();
        if (sfx.name === 'button') buttonControls.play();
    }, [sfx.name]);
    useEffect(() => {
        if (starState.playing) {
            ambientControls.volume(0.1);
        } else {
            ambientControls.volume(0.3);
        }
    }, [starState.playing]);
    useEffect(() => {
        if (
            (stars.stars > 0 &&
                stars.options[stars.options.length - 1] === 'yes') ||
            stars.isLastScene
        ) {
            starControls.play();
        }
    }, [stars.stars, stars.isLastScene]);
    useEffect(() => {
        if (sfx.notification) notificationControls.play();
    }, [sfx.notification]);
    useEffect(() => {
        if (sfx.driving) {
            drivingControls.play();
        } else {
            drivingControls.pause();
        }
    }, [sfx.driving]);
    useEffect(() => {
        if (sfx.startCar) {
            carStartControls.play();
        }
    }, [sfx.startCar]);
    useEffect(() => {
        if (sfx.hint) hintControls.play();
    }, [sfx.hint]);

    useEffect(() => {
        // if (sfx.ambient) ambientControls.play();
    }, [sfx.ambient]);
    return (
        <>
            {step.step >= 1 && ambientSound}
            {!isIOS && (
                <>
                    {starSound}
                    {driving}
                    {carStart}
                    {notification}
                    {hint}
                    {petrol}
                    {button}
                </>
            )}
        </>
    );
};
