import { useSpring } from '@react-spring/core';
import React, { useEffect } from 'react';
import {
    useCar,
    useLoading,
    useName,
    useStars,
    useSteps,
    usePreferences,
    useDebug,
    useSFX
} from '../../stores';
import { TextButtonBox } from './OptionsButton';
import { springs } from '../../commonStyles';
import { animated as a } from 'react-spring';
import { useState } from 'react';
import { Cutscene } from './Cutscene';
import { videos, types } from '../../constants';
import { Hints } from './Hints';
import { ThoughtBubble } from './Thought';
import { NotificationFlex } from './NotificationFlex';
import { Introduction } from '../selection/Introduction';
import Analytics, { sendEvent } from '../selection/Analytics';

const phases = {
    preLaptop: 0,
    introduction: 2,
    laptop: 5,
    laptopText: 7,
    dad: 10,
    dadText: 15,
    carIdle: 20,
    moveToCafe: 25,
    outsideCafe: 30,
    cafeScene: 35,
    kycDone: 37,
    noKyc: 37.2,
    whatAboutYou: 38,
    cafeSceneText: 40
};

//['bankAccountOpen', 'isKYC']
export const Step1 = () => {
    const steps = useSteps();
    const loader = useLoading();
    const sfx = useSFX();
    const { speed } = useDebug();
    const car = useCar();
    const name = useName().name;
    const prefs = usePreferences();
    const [phase, setPhase] = useState(phases.preLaptop);
    const stars = useStars();
    const [analyticsActions, setAnalyticsActions] = useState('');
    const [choice, setChoices] = useState(null);

    const preLaptop = useSpring({
        from: { opacity: 0 },
        opacity:
            phase === phases.preLaptop || phase === phases.introduction ? 1 : 0,
        config: springs.cutscene
    });
    
    const preLaptopText = useSpring({
        from: { transform: 'translate(-1600px,0px)' },
        transform:
            phase === phases.introduction && (!types[prefs.preferences.type] || !prefs.preferences.type)
                ? 'translate(0px,0px)'
                : 'translate(-1600px,0px)',
        config: springs.slow,
        delay: phase === phases.introduction ? 900 : 0
    });
    const laptop = useSpring({
        from: { opacity: 0 },
        opacity: phase === phases.laptop || phase === phases.laptopText ? 1 : 0,
        config: springs.cutscene
    });

    const dadSpring = useSpring({
        from: { opacity: 0 },
        opacity: phase === phases.dad || phase === phases.dadText ? 1 : 0,
        config: springs.cutscene
    });

    const cafeSpring = useSpring({
        from: { opacity: 0 },
        opacity:
            (phase === phases.cafeScene ||
                phase === phases.cafeSceneText ||
                phase === phases.kycDone ||
                phase === phases.whatAboutYou ||
                phase === phases.noKyc) &&
            steps.step === 1
                ? 1
                : 0,
        config: springs.cutscene
    });

    const {
        preferences: { avatar }
    } = usePreferences();

    useEffect(() => {
        switch (analyticsActions) {
            case 'bankAccountOpen':
                sendEvent('perfect_opener', choice);
                break;
            case 'isKYC':
                sendEvent('kyc_Champion', choice);
                break;
            default:
                break;
        }
    }, [analyticsActions]);

    return (
        loader.progress === loader.totalItems && (
            <>
                {phase >= phases.preLaptop && (
                    <Cutscene
                        video={videos.preLaptop}
                        seemless
                        style={{ ...preLaptop }}
                        delay={3500 / speed}
                        onEnd={() => setPhase(phases.introduction)}
                    />
                )}
                {phase >= phases.preLaptop && (
                    <>
                        <Introduction
                            style={{ ...preLaptopText }}
                            handleClick={(i) => {
                                stars.add('yes');
                                prefs.setType(i);
                                setPhase(phases.laptop);
                                setAnalyticsActions('bankAccountOpen');
                                setChoices(types[prefs.preferences.type]);
                            }}
                        />
                        <TextButtonBox
                            heading={`Welcome, ${name}`}
                            text={`Congratulations on opening a ${types[prefs.preferences.type]} HDFC Bank Account`}
                            buttonText='Continue'
                            topLeft
                            condition={phase===phases.introduction && prefs.preferences.type}
                            handleClick={()=>{
                                stars.add('yes');
                                // prefs.setType(i);
                                setPhase(phases.laptop);
                                setAnalyticsActions('bankAccountOpen');
                                setChoices(types[prefs.preferences.type]);
                            }}
                        />

                        {/*  */}
                        {/* {sendEvent(
                            'perfect_opener',
                            types[prefs.preferences.type]
                        )} */}
                        {/* <Analytics
                            context={'perfect_opener'}
                            value={types[prefs.preferences.type]}
                        /> */}
                    </>
                    //** BANK ACCOUNT PREFERENCE */
                )}
                {phase >= phases.laptop && (
                    <Cutscene
                        video={videos.laptop}
                        seemless
                        style={{ ...laptop }}
                        timeBasedFunction={[
                            [6500, () => setPhase(phases.laptopText)]
                        ]}
                    />
                )}
                {
                    <TextButtonBox
                        heading={`Meet ${avatar === 1 ? 'Hitesh' : 'Hema'}.`}
                        text={`${
                            avatar === 1 ? 'He' : 'She'
                        }'s just opened an HDFC Bank Account too. /nNow ${
                            avatar === 1 ? 'he' : 'she'
                        }'s off on a trip. Ready to join ${
                            avatar === 1 ? 'him' : 'her'
                        }?`}
                        buttonText="Let's Go!"
                        topLeft
                        condition={phase === phases.laptopText}
                        handleClick={() => setPhase(phases.dad)}
                    />
                }
                {phase >= phases.dad && (
                    <Cutscene
                        timeBasedFunction={[
                            [8000, () => setPhase(phases.dadText)]
                        ]}
                        video={videos.dad}
                        style={{ ...dadSpring }}
                    />
                )}
                {
                    <TextButtonBox
                        heading="Quest Unlocked: Onboard on the Go!"
                        text={
                            (avatar === 1 ? 'Hitesh' : 'Hema') +
                            ` is pretty confident - do you think you can help activate ${
                                avatar === 1 ? 'his' : 'her'
                            } HDFC bank account while  ${
                                avatar === 1 ? 'he' : 'she'
                            } travels?`
                        }
                        buttonText="Yes, Let's do this"
                        topLeft
                        condition={phase === phases.dadText}
                        handleClick={() => {
                            setTimeout(() => sfx.setStartCar(), [2000]);
                            setPhase(phases.carIdle);
                        }}
                    />
                }
                {
                    <TextButtonBox
                        heading="Here We Go!"
                        text={`${name}, your mission is to activate yours and  ${
                            avatar === 1 ? 'Hitesh' : 'Hema'
                        }'s HDFC Bank account. Remember every star you earn leads you to bigger & better offers!`}
                        buttonText="Start Trip"
                        condition={phase === phases.carIdle}
                        delay={phase === phases.carIdle ? 1600 / speed : 0}
                        topLeft
                        handleClick={() => {
                            setPhase(phases.moveToCafe);
                            car.animations.Anim_1.paused = false;
                            sfx.setDriving(true);
                            setTimeout(() => {
                                sfx.setDriving(false);

                                setPhase(phases.outsideCafe);
                            }, [
                                (car.animations.Anim_1._clip.duration * 1000 -
                                    500) /
                                    speed
                            ]);
                        }}
                    />
                }
                {
                    <NotificationFlex>
                        <ThoughtBubble
                            idx={0}
                            delay={9500 / speed}
                            duration={11111}
                            condition={() =>
                                phase === phases.moveToCafe ||
                                phase === phases.outsideCafe
                            }
                        />
                        <ThoughtBubble
                            delay={3000 / speed}
                            idx={7}
                            duration={11111}
                            condition={() =>
                                phase === phases.moveToCafe ||
                                phase === phases.outsideCafe
                            }
                        />
                    </NotificationFlex>
                }
                {
                    <TextButtonBox
                        heading="Time for a Break"
                        text="Let's grab a coffee and get that KYC done right away."
                        buttonText="Enter Café"
                        condition={phase === phases.outsideCafe}
                        topLeft
                        handleClick={() => setPhase(phases.cafeScene)}
                    />
                }
                {phase >= phases.cafeScene && (
                    <Cutscene
                        seemless
                        video={videos.cafe}
                        style={{ ...cafeSpring }}
                        timeBasedFunction={[
                            [14000, () => setPhase(phases.kycDone)]
                        ]}
                    />
                )}
                {
                    <TextButtonBox
                        heading=" Video KYC done in 5 minutes."
                        text={`Now ${
                            avatar === 1 ? 'Hitesh' : 'Hema'
                        } has access to all exclusive HDFC Bank perks!`}
                        buttonText="Next"
                        topLeft
                        condition={phase === phases.kycDone}
                        handleClick={() => setPhase(phases.whatAboutYou)}
                    />
                }
                {
                    <>
                        <TextButtonBox
                            heading={`What about you, ${name}? `}
                            text="Have you done your KYC yet?"
                            buttonOptions={['Yes', 'No']}
                            condition={phase === phases.whatAboutYou}
                            clickOptions={[
                                () => {
                                    stars.add('yes');
                                    setPhase(phases.cafeSceneText);
                                    setChoices(true);
                                },
                                () => {
                                    stars.add('no');
                                    setPhase(phases.noKyc);
                                    setChoices(false);
                                }
                            ]}
                            handleClick={() => {
                                setAnalyticsActions('isKYC');
                            }}
                            topLeft
                        />
                        {/* {sendEvent(
                            'kyc_Champion',
                            phase === phases.cafeSceneText
                                ? 'Yes'
                                : phase === phases.noKyc
                                ? 'No'
                                : undefined
                        )} */}
                        {/* <Analytics
                            context={'kyc_Champion'}
                            value={
                                phase === phases.cafeSceneText
                                    ? 'Yes'
                                    : phase === phases.noKyc
                                    ? 'No'
                                    : undefined
                            }
                        /> */}
                        ;
                    </>
                    //** KYC PREFERENCE */
                }
                {
                    <TextButtonBox
                        heading={`Get your KYC done, ${name}!`}
                        text={`Well, you've seen how it's done - it's easy and quick, and you'll enjoy 100% benefits & perks of your ${
                            types[prefs.preferences.type]
                        } Account. You will receive your Debit Card & enjoy all benefits post your KYC only`}
                        topLeft
                        condition={phase === phases.noKyc}
                        delay={phase === phases.noKyc ? 400 / speed : 0}
                        buttonText="Next"
                        handleClick={() => setPhase(phases.cafeSceneText)}
                    />
                }
                {phase >= phases.cafeScene && (
                    <Hints
                        heading="Keep the following ready to ensure you finish your KYC quickly:"
                        noShow
                        hints="A smartphone with stable internet,A blank white sheet of paper,Black or Blue Pen,Orginal PAN Card,Presence in India"
                        style={{
                            ...cafeSpring,
                            pointerEvents:
                                phase === phases.cafeScene ||
                                phase === phases.cafeSceneText ||
                                phase === phases.kycDone ||
                                phase === phases.whatAboutYou ||
                                phase === phases.noKyc
                                    ? 'auto'
                                    : 'none'
                        }}
                    />
                )}
                {
                    <TextButtonBox
                        topLeft
                        condition={
                            phase === phases.cafeSceneText && steps.step === 1
                        }
                        delay={
                            phase === phases.cafeSceneText &&
                            steps.step === 1 &&
                            stars.options[1] === 'yes'
                                ? 1900 / speed
                                : 0
                        }
                        heading="You paid for that coffee and now you're out of cash!"
                        text="Find an ATM nearby to activate your new HDFC Bank debit card and withdraw cash."
                        buttonText="Use HDFC Bank ATM locator"
                        handleClick={() => steps.setStep(2)}
                    />
                }
            </>
        )
    );
};
