import { BakeShadows, useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';
import { useLoading, useSteps } from '../../stores';
import { useAnimations } from '@react-three/drei';
import { LoopPingPong } from 'three';
import * as THREE from 'three';
import { base } from '../../constants';

const Prim = React.memo(({ object }) => {
    const ref = useRef();
    const loader = useLoading();
    const steps = useSteps();

    useEffect(() => {
        loader.reportDone();
    }, []);

    return !steps.cutscene ? (
        <primitive ref={ref} object={object.scene} />
    ) : null;
});
export const AllMeshes = () => {
    const floor = useGLTF(base + '/content/environment/Floor.glb');
    const object = useGLTF(
        base + '/content/HDFC/HDFC.gltf',
        'https://www.gstatic.com/draco/versioned/decoders/1.5.0/'
    );
    const { actions } = useAnimations(object.animations, object.scene);
    // const loader = useLoading();
    const [draco, setDraco] = useState();
    const { scene } = useThree();

    const objects = [object, floor];
    const steps = useSteps();

    useEffect(() => {
        if (draco) {
            scene.add(new THREE.Mesh(draco));
        }
    }, [draco]);

    const setShadow = (mesh) => {
        if (mesh.type == 'Mesh') {
            mesh.castShadow = true;
            mesh.receiveShadow = true;
        }
    };

    useEffect(() => {
        objects.map((x) =>
            x.scene.traverse((node) => {
                // console.log('object: ', node);
                if (node.name === 'car1001') {
                    node.visible = false;
                }
                setShadow(node);
            })
        );
        // Object.values(actions)?.forEach((anim) => {
        //     anim.paused = false;
        //     anim.loop = LoopPingPong;
        //     anim.play();
        // });
    }, []);

    return (
        <>
            <BakeShadows />
            {objects.map((object, i) => (
                <Prim key={i} object={object} />
            ))}
        </>
    );
};
