import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSpring } from 'react-spring';
import { useName, usePreferences } from '../../stores';
import { animated as a } from '@react-spring/web';
import { colorThemes, springs, textStyles } from '../../commonStyles';
import { OptionsButton } from '../UI/OptionsButton';
import { useHistory } from 'react-router';
import { types as options } from '../../constants';
export const Introduction = (props) => {
    const prefs = usePreferences();
    const name = useName().name;

    const check = () => {
        return (
            prefs.preferences.avatar &&
            prefs.preferences.g &&
            !prefs.preferences.type
        );
    };

    const history = useHistory();

    const [show, setShow] = useState(false);

    const { opacity } = useSpring({
        opacity: check() ? 1 : 0,
        onRest: () => !check() && setShow(false),
        config: springs.slow
    });

    useEffect(() => {
        if (!prefs.preferences.type) setShow(true);
    }, [prefs]);
    return (
        <a.div style={{ ...props.style }} className={css(styles.textContainer)}>
            <h1
                style={{ marginBottom: '-1%' }}
                className={css(textStyles.heading48, styles.head)}
            >
                Welcome
            </h1>
            <p className={css(textStyles.para18, styles.para)}>
                Select your HDFC Bank Account, {name}
            </p>
            <div className={css(styles.flex)}>
                {options.map((x, i) => {
                    return (
                        x && (
                            <OptionsButton
                                key={i}
                                onClick={() => props.handleClick(i)}
                                text={x}
                            />
                        )
                    );
                })}
            </div>
        </a.div>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        boxShadow: '#08A4CF 0px -4px inset',
        // outline: '0.25vh #212121 solid',
        overflow: 'visible',
        // backgroundColor:'red',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 1000,
        textAlign: 'left',
        padding: '6% 0 0 0%'
        // '@media (max-width: 1200px)':{
        //     paddingTop:'2vw'
        // }
    },
    head: {
        fontFamily: 'Fredoka One',
        fontSize: '5.3vh',
        marginTop: '2.7vh'
        // color:colorThemes.brightBlue,
        // fontSize:
    },
    para: {
        fontFamily: 'Fredoka One',
        fontSize: '2.5vh'
        // color:colorThemes.brightBlue,
        // fontSize:
    },
    buttonText: {},
    imgContainer: {
        position: 'absolute',
        width: '50%',
        height: '91%',
        bottom: 0,
        left: 0,
        '@media (max-width: 900px)': {
            width: '40%'
        }
    },
    textContainer: {
        position: 'absolute',
        width: '40%',
        // height:'80%',
        boxShadow: `${colorThemes.blue} 0px -4px inset`,
        // outline: `0.25vh #212121 solid`,
        top: '22%',
        left: '5%',
        zIndex: 9999,
        backgroundColor: 'rgba(255,255,255,0.9)',
        borderRadius: '5px',
        boxSizing: 'border-box',
        padding: '0% 2% 2% 2%',
        textAlign: 'left'
        // '@media (max-width: 900px)':{
        //     top:'2%',
        //     width:'60%'
        // },
        // '@media (max-height: 420px)':{
        //     top:'2%',
        //     paddingTop:'1%'
        // }
    },
    flex: {
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        // padding:'0 4% 0 0%',
        width: '100%'
        // backgroundColor:'red',
        // '@media (max-width: 1200px)':{
        //     width:'100%',
        //     // marginTop:'-2%'
        // },
        // '@media (max-width: 900px)':{
        //     width:'100%',
        //     marginTop:'-2%'
        // }
    },
    MainButtonMargin: {
        marginTop: '10%',
        '@media (max-width: 1200px)': {
            marginTop: '5%'
        },
        '@media (max-width: 900px)': {
            marginTop: '2%'
        }
    }
});
