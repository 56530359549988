import { css, StyleSheet } from 'aphrodite';
import { commonStyles, springs, textStyles } from '../../../commonStyles';
import { nostar, starUrls } from '../../../constants';
import { useStars } from '../../../stores';
import { useSpring } from '@react-spring/core';
import { animated as a } from 'react-spring';
import { KFCPromo } from '../KFCPromo';
import { useMemo } from 'react';

import Coupons from './Coupons';
import { isMobile } from 'react-device-detect';
const text = {
    allstar: [
        'Congratulations, you made it! You are an all star!     ',
        'You successfully picked HDFC Bank for your financial needs and availed services that saved your time and money.'
    ],
    medstar: [
        'So close to being an all-star! You availed some benefits but missed out on a few.',
        'Enjoy your offers below. To unlock all offers, get all the stars.'
    ],
    nostar: [
        "Not a bad attempt, but you didn't avail all of the benefits.",
        "Let's retry again and this time, try a few different choices to get stars and unlock offers - you can do it!  "
    ]
};
const delay = 11000;
const TextBox = ({ text, count }) => {
    const heading = useSpring({
        from: { opacity: 0 },
        opacity: 1,
        config: springs.slow,
        delay: delay + 2500
    });
    const textSpring = useSpring({
        from: { opacity: 0 },
        opacity: 1,
        config: springs.slow,
        delay: delay + 5000
    });
    return (
        <a.div className={css(styles.textBox, textStyles.para18)}>
            <a.h1 style={heading} className={css(styles.text1, styles.text)}>
                {`${count}/8 ` + text[0]}
            </a.h1>
            <a.h1 style={textSpring} className={css(styles.text, styles.text2)}>
                {text[1]}
            </a.h1>
            {!isMobile && (
                <a.p
                    className={css(styles.text, styles.text2)}
                    style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        ...textSpring
                    }}
                >
                    <div
                        onClick={() => (window.location = window.location)}
                        className={css(styles.Replay)}
                    >
                        {' '}
                        Replay The Game{' '}
                    </div>
                </a.p>
            )}
            {/* {count !== 0 && <KFCPromo style={textSpring} />} */}
        </a.div>
    );
};
export const EndScreen = () => {
    const stars = useStars();
    const starCount = useMemo(() => {
        console.log('stars', stars.options);
        return stars.options.filter((x) => x == 'yes').length;
    }, [stars]);
    let getYesCount = () => starCount;
    const spring = useSpring({
        opacity: stars.isLastScene ? 1 : 0,
        from: { opacity: 0, transform: 'translate(-50%,-50%) scale(0)' },
        transform: 'translate(-50%,-50%) scale(1.2)',
        delay: delay,
        config: springs.slow
    });
    return (
        <>
            <a.div style={spring} className={css(styles.container)}>
                {new Array(8).fill(1).map((x, i) => {
                    return (
                        <img
                            className={css(styles.image)}
                            src={
                                stars.options[i] === 'no' ? nostar : starUrls[i]
                            }
                            alt="star"
                        />
                    );
                })}
            </a.div>
            <a.div>
                <TextBox
                    count={getYesCount()}
                    text={
                        getYesCount() === 8
                            ? text.allstar
                            : getYesCount() > 3
                            ? text.medstar
                            : text.nostar
                    }
                />
            </a.div>
            <Coupons spring={spring} getYesCount={getYesCount} />
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: '20%',
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
        left: '50%',
        zIndex: 10000
    },
    image: {
        width: '16vh',
        height: '16vh'
    },
    textBox: {
        position: 'absolute',
        zIndex: 10001,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        bottom: '26%',
        left: '10%',
        width: '80%',
        height: '40%',
        textAlign: 'center'
    },
    text2: {
        marginTop: 0
    },
    text: {
        color: 'white',
        fontSize: '3.4vh'
    },
    Replay: {
        width: 'max-content',
        display: 'inline-block',
        padding: '10px 50px',
        borderRadius: '40px',
        backgroundColor: '#005AC2'
    }
});
