import React from 'react'
import { StyleSheet, css } from 'aphrodite'
export const NotificationFlex = ({children}) => {
    return (
        <div className={css(styles.container)}>
            {children}
        </div>
    )
}

const styles = StyleSheet.create({
    container:{
        display:'flex',
        width:'42%',
        flexDirection:'column-reverse',
        position:'absolute',
        bottom:'2%',
        right:'2%',
        transition:'2000ms all',
        textAlign:'right'
    }
})