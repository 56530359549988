import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { useSpring } from '@react-spring/core';
import { useSteps } from '../../stores';
import { animated as a } from 'react-spring';
import { Intro } from './Intro';

export const PagesContainer = (props) => {
    const steps = useSteps();
    const [show, setShow] = useState(true);
    const { opacity } = useSpring({
        opacity: !steps.step ? 1 : 0,
        onRest: () => steps.step && setShow(false)
    });

    useEffect(() => {
        if (steps.step === 0) setShow(true);
    }, [steps.step]);
    return (
        show && (
            <a.div style={{ opacity }} className={css(styles.container)}>
                <Intro />
            </a.div>
        )
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 4
    }
});
