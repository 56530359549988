import { useGLTF } from '@react-three/drei';
import React, { useRef } from 'react';
import { useAnimations } from '@react-three/drei';
import { useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useIdleCar } from '../../stores';
import { base } from '../../constants';

export const IdleCar = React.memo((props) => {
    const object = useGLTF(base + '/content/environment/Car_Stationary.glb');
    const ref = useRef();
    const { actions } = useAnimations(object.animations, object.scene);
    const idle = useIdleCar();

    useEffect(() => {
        if (idle.shouldMove)
            setTimeout(() => {
                idle.setShouldMove(false);
                ref.current.position.set(1000, 1000, 10000);
            }, [7000]);
    }, [idle.shouldMove]);

    useFrame((state) => {
        if (idle.shouldMove) {
            ref.current.position.setZ(ref.current.position.z + 0.82);
        }
    });

    return (
        <>
            <primitive ref={ref} object={object.scene} />
        </>
    );
});
