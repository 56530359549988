/* eslint-disable default-case */
import { useSpring } from '@react-spring/core';
import React, { useEffect } from 'react';
import {
    useCar,
    useStars,
    useName,
    usePreferences,
    useDebug,
    useSFX
} from '../../stores';
import { TextButtonBox } from './OptionsButton';
import { animated as a } from 'react-spring';
import { useState } from 'react';
import { Cutscene } from './Cutscene';
import { types, videos } from '../../constants';
import { Hints } from './Hints';
import { springs } from '../../commonStyles';
import { ThoughtBubble } from './Thought';
import { NotificationFlex } from './NotificationFlex';
import { sendEvent } from '../selection/Analytics';

const phases = {
    moveToPump: 10,
    pumpTextbox: 15,
    pumpAfterPayment: 20,
    pumpYou: 22,
    pumpContinue: 23,
    moveToDriveIn: 25,
    driveInTextbox: 30,
    driveInAfterPayment: 35,
    driveInYou: 37,
    driveInContinue: 38,
    driveHome: 40,
    beforeHomeText: 42,
    atHome: 45,
    continue: 50,
    continueTextbox: 55,
    continueYou: 57,
    continueContinue: 58,
    end: 60,
    lastSceneText: 65
};
export const Step3 = () => {
    const car = useCar();
    const name = useName().name;
    const { speed } = useDebug();
    const stars = useStars();
    const [phase, setPhase] = useState(phases.moveToPump);
    const [petrol, setPetrol] = useState(false);
    const [bill, setBill] = useState(false);
    const [restraunt, setRestraunt] = useState(false);
    const prefs = usePreferences();
    const {
        preferences: { avatar }
    } = usePreferences();
    const sfx = useSFX();

    useEffect(() => {
        car.setAnimation('Anim_3');
        setTimeout(() => {
            sfx.setDriving(true);
            car.animations.Anim_3.paused = false;

            setTimeout(() => {
                setPhase(phases.pumpTextbox);
            }, [
                (car.animations['Anim_3']._clip.duration * 1000 - 2000) / speed
            ]);

            setTimeout(() => {
                sfx.setDriving(false);
            }, [(car.animations['Anim_3']._clip.duration * 1000) / speed]);
        }, 50);
    }, []);

    useEffect(() => {
        if (phase === phases.pumpAfterPayment) sfx.setName('petrol');
    }, [phase]);

    const [analyticsActions, setAnalyticsActions] = useState('');
    const [choice, setChoices] = useState(null);

    useEffect(() => {
        switch (analyticsActions) {
            case 'useRefuling':
                sendEvent('pitstop_pro', choice);
                break;
            case 'useResturant':
                sendEvent('super_saver', choice);
                break;
            case 'useBillPay':
                sendEvent('recharge_rockstar', choice);
                break;
            default:
                break;
        }
    }, [analyticsActions]);

    const atHomeScene = useSpring({
        from: { opacity: 0 },
        opacity:
            (phase === phases.atHome ||
                phase === phases.continueYou ||
                phase === phases.continueTextbox ||
                phase === phases.continueContinue) &&
            !bill
                ? 1
                : 0,
        config: springs.cutscene
    });

    const continueVideo = useSpring({
        from: { opacity: 0 },
        opacity:
            (phase === phases.continue ||
                phase === phases.continueTextbox ||
                phase === phases.continueContinue ||
                phase === phases.continueYou) &&
            bill
                ? 1
                : 0,
        config: springs.cutscene
    });

    const lastScene = useSpring({
        from: { opacity: 0 },
        opacity: phase >= phases.end ? 1 : 0,
        config: springs.cutscene
    });

    function SwitchOnVideo() {
        switch (prefs.preferences.type) {
            case 1:
                return videos.skySavingMax;
            case 2:
                return videos.skyWomen;
            case 3:
            case 6:
            case 7:
                return videos.skyRegular;
            case 4:
                return videos.skyDigiSaving;
            case 5:
                return videos.skySeniorCitizen;
        }
    }

    return (
        <>
            {
                <NotificationFlex>
                    <ThoughtBubble
                        idx={3}
                        delay={11000 / speed}
                        duration={111111}
                        condition={() =>
                            phase === phases.moveToPump ||
                            phase === phases.pumpTextbox
                        }
                    />
                </NotificationFlex>
            }

            {
                <TextButtonBox
                    condition={phase === phases.pumpTextbox}
                    heading="How will you pay?"
                    text="Hint: Enjoy 0% Surcharge on Fuel spends* on select HDFC Bank Debit Cards. T&C app."
                    buttonOptions={['Cash', 'HDFC Bank Debit Card', 'UPI']}
                    clickOptions={[() => {}, () => setPetrol(true), () => {}]}
                    topLeft
                    handleClick={() => {
                        setPhase(phases.pumpAfterPayment);
                        car.setAnimation('Anim_5'); //anim 4 is cafe
                    }}
                />
            }
            {
                <TextButtonBox
                    condition={phase === phases.pumpAfterPayment}
                    text={
                        !petrol
                            ? `There's no surcharge on UPI and using ${
                                  avatar === 1 ? 'Hitesh' : 'Hema'
                              }'s HDFC Bank Debit Card would've qualified you for extra savings and benefits!`
                            : 'Your car is fueled and you now enjoy 0% Surcharge on Fuel spends on select Debit Cards.'
                    }
                    heading={!petrol ? 'Hmmm..' : 'Perfect!'}
                    buttonText="Next"
                    topLeft
                    handleClick={() => {
                        setPhase(phases.pumpYou);
                    }}
                />
            }
            {
                <TextButtonBox
                    condition={phase === phases.pumpYou}
                    heading={`What about you, ${name}?`}
                    text="Have you ever tried refueling with an HDFC Bank Debit Card?"
                    buttonOptions={['Yes', 'No']}
                    clickOptions={[
                        () => {
                            stars.add('yes');
                            setTimeout(() => sfx.setStartCar(), [1500]);
                            setPhase(phases.moveToDriveIn);
                            setChoices(true);
                            setTimeout(() => {
                                sfx.setDriving(true);
                                car.animations['Anim_5'].paused = false;
                                // car.animations.Anim_5
                                setTimeout(() => {
                                    setPhase(phases.driveInTextbox);
                                }, [
                                    (car.animations.Anim_5._clip.duration *
                                        1000 -
                                        500) /
                                        speed
                                ]);
                                setTimeout(() => {
                                    sfx.setDriving(false);
                                }, [
                                    (car.animations.Anim_5._clip.duration *
                                        1000) /
                                        speed
                                ]);
                            }, [1500 / speed]);
                        },
                        () => {
                            sfx.setStartCar();
                            stars.add('no');
                            setChoices(false);
                            setPhase(phases.pumpContinue);
                        }
                    ]}
                    topLeft
                    handleClick={() => {
                        setAnalyticsActions('useRefuling');
                    }}
                />
            }
            {
                <TextButtonBox
                    topLeft
                    condition={phase === phases.pumpContinue}
                    heading={`You are missing out, ${name}!`}
                    text={`Enjoy 0% surcharge on any fuel spends with your HDFC Bank Debit Card`}
                    buttonText={'Continue Journey'}
                    handleClick={() => {
                        setPhase(phases.moveToDriveIn);
                        // setTimeout(()=>{
                        sfx.setDriving(true);
                        car.animations['Anim_5'].paused = false;
                        // car.animations.Anim_5
                        setTimeout(() => {
                            setPhase(phases.driveInTextbox);
                        }, [
                            (car.animations.Anim_5._clip.duration * 1000 -
                                500) /
                                speed
                        ]);
                        setTimeout(() => {
                            sfx.setDriving(false);
                        }, [
                            (car.animations.Anim_5._clip.duration * 1000) /
                                speed
                        ]);

                        // },[1700])
                    }}
                />
            }
            {
                <NotificationFlex>
                    <ThoughtBubble
                        idx={4}
                        delay={1000 / speed}
                        text="My tummy is making noises! I should grab something to eat before heading home."
                        duration={111111}
                        condition={() =>
                            phase === phases.moveToDriveIn ||
                            phase === phases.driveInTextbox
                        }
                    />
                </NotificationFlex>
            }

            {
                <TextButtonBox
                    condition={phase === phases.driveInTextbox}
                    heading="How will you pay?"
                    text="Hint: Enjoy Great discounts and offers on HDFC Bank Debit Card Payments."
                    buttonOptions={['Cash', 'HDFC Bank Debit Card', 'UPI']}
                    clickOptions={[
                        () => {},
                        () => setRestraunt(true),
                        () => {}
                    ]}
                    topLeft
                    handleClick={() => {
                        setPhase(phases.driveInAfterPayment);
                        car.setAnimation('Anim_6'); //anim 4 is cafe
                    }}
                />
            }
            {
                <TextButtonBox
                    condition={phase === phases.driveInAfterPayment}
                    heading="Yummy, Your bill is paid!"
                    text={
                        restraunt
                            ? `${
                                  avatar === 1 ? 'Hitesh' : 'Hema'
                              } will now enjoy exclusive discounts and offers on HDFC Bank Debit Card payments.`
                            : 'Your bill is paid but you missed on exclusive discounts and offers from HDFC Bank.'
                    }
                    buttonText="Next"
                    topLeft
                    handleClick={() => {
                        setPhase(phases.driveInYou);
                    }}
                />
            }
            {
                <TextButtonBox
                    heading={`What about you, ${name}?`}
                    condition={phase === phases.driveInYou}
                    text="Have you ever paid at a restaurant with an HDFC Bank Debit Card?"
                    buttonOptions={['Yes', 'No']}
                    topLeft
                    clickOptions={[
                        () => {
                            stars.add('yes');
                            setTimeout(() => sfx.setStartCar(), [1500 / speed]);
                            setPhase(phases.driveHome);
                            setChoices(true);
                            setTimeout(() => {
                                sfx.setDriving(true);
                                car.animations.Anim_6.paused = false;
                                setTimeout(() => {
                                    sfx.setDriving(false);
                                    setPhase(phases.beforeHomeText);
                                }, [
                                    (car.animations.Anim_6._clip.duration *
                                        1000) /
                                        speed
                                ]);
                            }, [1799 / speed]);
                        },
                        () => {
                            sfx.setStartCar();
                            stars.add('no');
                            setPhase(phases.driveInContinue);
                            setChoices(false);
                        }
                    ]}
                    handleClick={() => {
                        setAnalyticsActions('useResturant');
                    }}
                />
            }
            {
                <TextButtonBox
                    heading={`You are missing out, ${name}`}
                    text={`Enjoy exciting discounts at restaurants with your HDFC Bank Debit Card.`}
                    topLeft
                    buttonText="Continue"
                    condition={phase === phases.driveInContinue}
                    handleClick={() => {
                        setPhase(phases.driveHome);
                        // sfx.setStartCar()  // becuase already played when clikcing options
                        sfx.setDriving(true);
                        car.animations.Anim_6.paused = false;
                        setTimeout(() => {
                            sfx.setDriving(false);
                            setPhase(phases.beforeHomeText);
                        }, [
                            (car.animations.Anim_6._clip.duration * 1000) /
                                speed
                        ]);
                    }}
                />
            }

            {
                <TextButtonBox
                    heading={`Good job, ${name}!`}
                    text={`You got ${
                        avatar === 1 ? 'Hitesh' : 'Hema'
                    } home after a pretty long day!`}
                    buttonText="Check Mail and Head Home"
                    condition={phase === phases.beforeHomeText}
                    topLeft
                    handleClick={() => setPhase(phases.atHome)}
                />
            }
            {phase >= phases.atHome && (
                <Cutscene
                    video={videos.bill}
                    style={{ ...atHomeScene }}
                    seemless
                />
            )}
            {
                <NotificationFlex>
                    <ThoughtBubble
                        idx={5}
                        condition={() =>
                            phase === phases.driveHome ||
                            phase === phases.beforeHomeText
                        }
                        delay={14000 / speed}
                        duration={111111}
                    />
                </NotificationFlex>
            }
            {
                <TextButtonBox
                    heading={`How will you clear ${
                        avatar === 1 ? 'Hitesh' : 'Hema'
                    }'s electricity bill?`}
                    text="Hint: BillPay is HDFC Bank's comprehensive bill payment solution."
                    buttonOptions={[
                        'Use HDFC Bank BillPay',
                        'Pay Tomorrow With Cash'
                    ]}
                    delay={phase === phases.atHome / speed ? 7000 : 0}
                    condition={phase === phases.atHome}
                    clickOptions={[
                        () => {
                            setBill(true);
                            setPhase(phases.continue);
                        },
                        () => setPhase(phases.continueTextbox)
                    ]}
                    topLeft
                    handleClick={() => {}}
                />
            }
            {phase >= phases.continue && (
                <Cutscene
                    video={videos.billPayed}
                    style={{ ...continueVideo }}
                    timeBasedFunction={[
                        [
                            8300,
                            () =>
                                phase === phases.continue &&
                                setPhase(phases.continueTextbox)
                        ]
                    ]}
                    seemless
                />
            )}

            {
                <TextButtonBox
                    text={
                        bill
                            ? 'Your bill is paid.'
                            : 'You spend all of tomorrow braving traffic and standing in line to pay by cash - such a waste of time and effort!'
                    }
                    heading={
                        bill
                            ? 'Lighting Fast!'
                            : 'Oof, this is going to be painful...'
                    }
                    buttonText="Continue"
                    condition={phase === phases.continueTextbox}
                    handleClick={() => setPhase(phases.continueYou)}
                    topLeft
                />
            }
            {
                <TextButtonBox
                    heading={`What about you, ${name}?`}
                    text={`Have you ever paid your bills via BillPay?`}
                    condition={phase === phases.continueYou}
                    buttonOptions={['Yes', 'No']}
                    clickOptions={[
                        () => {
                            stars.add('yes');
                            setPhase(phases.end);
                            setChoices(true);
                        },
                        () => {
                            stars.add('no');
                            setPhase(phases.continueContinue);
                            setChoices(false);
                        }
                    ]}
                    topLeft
                    handleClick={() => {
                        setAnalyticsActions('useBillPay');
                    }}
                />
            }
            {
                <TextButtonBox
                    topLeft
                    condition={phase === phases.continueContinue}
                    handleClick={() => setPhase(phases.end)}
                    buttonText="Continue"
                    heading={`Give it a try, ${name}`}
                    text={`BillPay is convenient and instant, and minimizes any risk of getting fined for late payment.`}
                />
            }
            {phase >= phases.end && (
                <Hints
                    heading="Here are a few more videos for your reference:"
                    // noShow
                    hints="Recharging FASTag with HDFC Bank: ,BillPay: ,Learn more about HDFC Bank's comprehensive bill payment solutions: "
                    links="https://www.youtube.com/watch?v=fIM9OZGmUvs,https://www.youtube.com/watch?v=svr_7sjtxfQ,https://www.youtube.com/watch?v=svr_7sjtxfQ"
                    style={{
                        ...lastScene,
                        pointerEvents: phase >= phases.end ? 'auto' : 'none'
                    }}
                />
            )}
            {phase >= phases.end && (
                <>
                    <Cutscene
                        video={SwitchOnVideo()}
                        seemless
                        style={{ ...lastScene }}
                        lastScene
                        // timeBasedFunction={[[11000,()=>setPhase(phases.lastSceneText)]]}
                        timeBasedFunction={[
                            [3900, () => setPhase(phases.lastSceneText)]
                        ]}
                    />
                </>
            )}
            {
                <TextButtonBox
                    heading={`You’ve Made ${
                        avatar === 1 ? 'Hitesh' : 'Hema'
                    }’s Life So Much Easier!`}
                    text={`Activating your HDFC ${
                        types[prefs.preferences.type]
                    } Bank Account and services unlocks a host of benefits for you and your family!`}
                    buttonText="Continue"
                    delay={phase === phases.lastSceneText ? 3000 / speed : 0}
                    condition={phase === phases.lastSceneText}
                    handleClick={() => {
                        stars.setIsLastScene(true);
                        setPhase(10000);
                    }}
                    topLeft
                />
            }
        </>
    );
};
