import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    useLoading,
    useParamsChanger,
    usePreferences,
    useSteps
} from '../../stores';
const accountTypesParams = {
    "savingsmax":1,
    "womens savings":2,
    "regular savings":3,
    "digi save youth":4,
    "senior citizen savings":5,
    "family banking":6,
    "saving farmers":7
}
export function getParams(searchStr) {
    if (searchStr === '') return {};
    let par = searchStr.slice(1, searchStr.length).split('&');
    let res = {};
    par.forEach((x) => (res[x.split('=')[0]] = x.split('=')[1]));
    return res;
}
const changePreferences = (prefs, params) => {
    prefs.setPreferences(params);
};
export const RouterChecker = () => {
    const loader = useLoading();
    const paramState = useParamsChanger();
    const location = useLocation();
    const steps = useSteps();
    const history = useHistory();
    const prefs = usePreferences();

    useEffect(() => {
        // paramState.params && history.push(paramState.params);
        
    }, [paramState.params]);
    const getType = (val) => {

        if(!val)return 0
        let removeSlash = (val) => val.split('').filter(x => x!='/').join('')
        const hypen = removeSlash(val.split('-').join(' ').toLowerCase())
        const type = removeSlash(val.toLowerCase())
        if( accountTypesParams.hasOwnProperty(type) ){
            return accountTypesParams[type]
        }
        if( accountTypesParams.hasOwnProperty(hypen) ){
            return accountTypesParams[hypen]
        }
    }
    useEffect(() => {
        let params = getParams( location.search)
        
        prefs.setPreferences({type: getType(params.type)})
        
    }, [location.search]);
    useEffect(()=>{
    },[prefs.preferences.type])

    useEffect(() => {
        if (prefs.preferences.avatar) {
            steps.setStep(1);
        } else {
            steps.setStep(0);
            steps.setCutscene(false);
            loader.reset();
        }
    }, [prefs.g, prefs.avatar]);

    return null;
};
