import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { springs } from '../../commonStyles';
import { MainButton } from '../UI/OptionsButton';
import { useName, usePreferences } from '../../stores';
import { useSpring } from '@react-spring/core';
import { animated as a } from 'react-spring';
import { introductionImages } from '../../constants';
import { Avatar } from './Avatar';

export const Intro = () => {
    const prefs = usePreferences();
    const [name, setName] = useState();
    const nameState = useName();
    const [bshowAvatar, setShouldShowAvatar] = useState(false);
    const check = () => !prefs.preferences.g;

    const { left, right, opacity } = useSpring({
        left: check() ? 'translateX(0px)' : 'translateX(-1500px)',
        right: check() ? 'translateX(0px)' : 'translateX(1000px)',
        opacity: check() ? 1 : 0,
        config: springs.slow
    });

    const constructLink = (param) => {
        prefs.setPreferences({ g: 1 });
        setShouldShowAvatar(true);
    };

    return (
        <>
            <a.img
                style={{ opacity }}
                className={css(styles.bg)}
                src={introductionImages.homepage}
            />
            <a.div
                style={{ opacity, pointerEvents: check() ? 'auto' : 'none' }}
                className={css(styles.container)}
            >
                <div className={css(styles.buttonMargin)}>
                    <div style={{ fontFamily: 'Lilita', textAlign: 'center' }}>
                        <input
                            placeholder="Enter Your Name"
                            style={{
                                width: '15%',
                                fontSize: '2vh',
                                fontFamily: 'Fredoka One',
                                height: '4vh',
                                marginBottom: '1%'
                            }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <MainButton
                        onClick={() => {
                            nameState.setName(
                                name.slice(0, 1).toUpperCase() +
                                    name.slice(1, name.length)
                            );
                            constructLink('g');
                        }}
                        disabled={!name}
                        text={'Begin'}
                    />
                </div>
            </a.div>
            {bshowAvatar ? <Avatar /> : null}
        </>
    );
};

const styles = StyleSheet.create({
    avatar: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '92%',
        width: '100%',
        zIndex: 760,
        boxSizing: 'border-box',
        padding: '5% 2% 2% 5%',
        textAlign: 'left'
    },
    container: {
        textAlign: 'center',
        position: 'absolute',
        right: 0,
        bottom: 0,
        height: '92%',
        zIndex: 770,
        width: '100%'
    },
    flex: {
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 4% 0 0%',
        width: '70%',
        marginTop: '22%',
        '@media (max-width: 900px)': {
            width: '100%'
        }
    },
    buttonMargin: {
        // background:"red",
        marginTop: '47vh'
    },
    bg: {
        // position: 'absolute',
        // pointerEvents: 'none',
        // top: 0,
        // left: 0,
        // width: '100%',
        // height: '100%',
        // zIndex: 760

        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'black',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    }
});
