export function sendEvent(context, action) {
    switch (context) {
        case 'perfect_opener':
            // console.log('perfect_opener :', action);
            break;
        case 'kyc_Champion':
            // action ? console.log('Yeah KYC done') : console.log('KYC not done');
            break;
        case 'eagle_eye':
            // action
            //     ? console.log('Yeah use atm locator')
            //     : console.log('No dont use atm locator');
            break;
        case 'super_swiper':
            // action
            //     ? console.log('Yeah use HDFC Card')
            //     : console.log('No dont use HDFC Card');
            break;
        case 'speed_Racer':
            // action
            //     ? console.log('Yeah use FastTag')
            //     : console.log('No dont use FastTag');
            break;
        case 'pitstop_pro':
            // action
            //     ? console.log('Yeah use Refuling')
            //     : console.log('No dont use Refuling');
            break;
        case 'super_saver':
            // action
            //     ? console.log('Yeah use Resturant')
            //     : console.log('No dont use Resturant');
            break;
        case 'recharge_rockstar':
            // action
            //     ? console.log('Yeah use Billpay')
            //     : console.log('No dont use Billpay');
            break;
        default:
            break;
    }
}

/**
 * 
 * !if we want to create a report
// function Analytics({ ...props }) {
//     const [reportObj, setreportObj] = useState({
//         perfect_opener: '',
//         kyc_Champion: '',
//         eagle_eye: '',
//         super_swiper: '',
//         speed_Racer: '',
//         pitstop_pro: '',
//         super_saver: '',
//         recharge_rockstar: '',
//         individual_star: ''
//     });
//[useATMLocator]
//     useEffect(() => {
//         console.log(reportObj);
//     }, [reportObj]);

//     if (props.value !== undefined && reportObj[props.context] === '') {
//         const key = props.context;
//         const value = props.value;
//         console.log(key, value);
//         setreportObj({ ...reportObj, [props.context]: props.value });
//     }

//     return null;
// }

// export default Analytics;
 */
