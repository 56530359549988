import React, { useEffect, useState } from 'react';
import { springs } from '../../commonStyles';
import { css, StyleSheet } from 'aphrodite';
import { base, thoughtBubble } from '../../constants';
import { useMeasure } from 'react-use';
import { useSpring } from '@react-spring/core';
import { animated as a } from 'react-spring';
import { usePreferences, useSFX } from '../../stores';

export const ThoughtBubble = ({
    text,
    time = 5000,
    delay = 4000,
    condition,
    ...props
}) => {
    const [ref, { height }] = useMeasure();
    const [show, set] = useState(false);
    const sfx = useSFX();
    // const [sound] = useAudio({ src: sounds.beep, autoPlay: true });
    const { preferences } = usePreferences();
    const spring = useSpring({
        from: { opacity: 0 },
        opacity: condition() ? 1 : 0,
        delay: condition() ? delay : 0,
        onStart: () => set(true),
        config: springs.slow
    });
    useEffect(() => {
        if (show) sfx.playNotification();
    }, [show]);
    return (
        show && (
            <>
                {/* {show && sound} */}
                {/* {sound} */}
                <a.img
                    className={css(styles.container)}
                    width={'100%'}
                    // width='100%'
                    style={{ ...spring }}
                    src={
                        base + thoughtBubble[props.idx][
                            preferences.avatar === 1 ? 'male' : 'female'
                        ]
                    }
                />
            </>
        )
    );
};

const styles = StyleSheet.create({
    container: {
        boxSizing: 'border-box',

        // transform:'scaleX(1) translateX(50%)',
        pointerEvents: 'none',
        // position: 'absolute',
        bottom: '4vh',
        right: '2vw',
        zIndex: 11000,
        // width: '100%',
        textAlign: 'right'
        // border: 'black 0.4vh solid',
        // padding: '2vh 2vw 2vh 4vw',
        // margin: '2vh 0vw 2vh 2vw',
        // backgroundColor: 'red'
    },
    none: {},
    width: {
        width: '35vw'
    },
    head: {
        position: 'absolute',
        height: '105%',
        bottom: '-0.1vh',
        left: 0,
        // backgroundColor:'red',
        transform: 'translateX(-50%)'
    }
});
