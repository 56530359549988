import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { useEffect } from 'react';
import React from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useDebug, useLoading } from '../../stores';
import { Vector3 } from 'three';
import { useWindowSize } from 'react-use';

export const Scan = () => {
    const { camera } = useThree();
    const loader = useLoading();
    const {width, height} = useWindowSize()
    const { speed } = useDebug();
    useEffect(() => {
        if (loader.totalItems - loader.progress === 1) {
            setTimeout(() => {
                camera.zoom = height<600?6.8:width>1900?16:12;
                loader.reportDone();
                loader.setSuspenseProgress(100);
            }, [3300]);
            const tween = new TWEEN.Tween(camera.position);
            tween.to(
                { x: 100, y: 200, z: 1000, prototype: Vector3 },
                3000 / speed
            );
            tween.onUpdate(({ x }) => {});
            tween.start();
        }
    }, [loader.progress]);
    useFrame(() => {
        TWEEN.update();
        camera.updateProjectionMatrix();
    });
    return <></>;
};
