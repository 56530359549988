import { useEffect } from 'react';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import * as THREE from 'three';
import {
    PMREMGenerator
    // ReinhardToneMapping,
    // RGBEEncoding,
    // sRGBEncoding
} from 'three';
import { useThree } from '@react-three/fiber';

let toneMappingOptions = {
    None: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping
};
let tonemappingParams = {
    exposure: 1,
    toneMapping: toneMappingOptions.ACESFilmic
};

const getHDRI = (name) => {
    return 'night_free_Env.hdr';
};

export default function Skybox(props) {
    const { gl, scene } = useThree();

    useEffect(() => {
        gl.toneMapping = tonemappingParams.toneMapping;
        // gl.toneMappingExposure = tonemappingParams.exposure;
        // gl.outputEncoding = THREE.sRGBEncoding;

        let pmremGenerator = new PMREMGenerator(gl);
        pmremGenerator.compileEquirectangularShader();
        new RGBELoader()
            .setDataType(THREE.UnsignedByteType)
            .setPath('./content/textures/')
            .load(getHDRI(props.name), function (texture) {
                const envMap =
                    pmremGenerator.fromEquirectangular(texture).texture;
                // gl.toneMapping = toneMappingOptions.ACESFilmic
                // envMap.encoding = sRGBEncoding
                // envMap.toneMapping = toneMappingOptions.Reinhard
                scene.environment = envMap;
                texture.dispose();
                pmremGenerator.dispose();
            });
    }, [gl]);

    return null;
}
