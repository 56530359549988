import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { colorThemes, springs, textStyles } from '../../commonStyles';
import { animated as a } from 'react-spring';
import { useSpring } from '@react-spring/core';
import { useSFX, useStars } from '../../stores';
import { isIOS, isMacOs } from 'react-device-detect';

const disabledButtons = ['hdfc bank debit card'];

export const MainButton = (props) => {
    const sfx = useSFX();

    const handleClick = () => {
        sfx.setName('button');
        props.onClick();
    };
    return (
        <button
            style={props.style}
            disabled={props.disabled}
            onClick={handleClick}
            className={css(
                textStyles.para18,
                styles.mainButton,
                props.small ? styles.smallerMainButton : styles.mainButton,
                props.disabled ? styles.disabled : styles.none
            )}
        >
            {props.text}
        </button>
    );
};
export const OptionsButton = (props) => {
    const ref = useRef();
    useEffect(() => {}, []);
    return (
        <button
            ref={props.ref}
            onClick={props.onClick}
            className={css(styles.button, textStyles.para14)}
        >
            {props.text}
        </button>
    );
};

export const PureText = (props) => {
    const spring = useSpring({
        from: { opacity: 0 },
        opacity: 1,
        delay: props.delay || 500
    });
    return (
        <a.div
            style={{ ...spring, ...props.style }}
            className={css(textStyles.para18, styles.pureText)}
        >
            {props.text}
        </a.div>
    );
};
export const TextButtonBox = (props) => {
    const [show, set] = useState(false);
    const spring = useSpring({
        delay: props.delay || 0,
        transform: props.condition
            ? 'translate(0vw,0vw)'
            : 'translate(-60vw,0vw)',
        from: { transform: 'translate(-60vw,0vw)' },
        config: springs.slow,
        onRest: () => !props.condition && setTimeout(() => set(false), [1500])
    });
    useEffect(() => {
        if (props.condition) set(true);
    }, [props.condition]);
    const stars = useStars();
    const localStyles = StyleSheet.create({
        white: {
            color: 'white'
        },
        offwhite: {
            color: '#f5f5f5'
        },
        blue: {
            color: colorThemes.darkerBlue
        }
    });
    return show ? (
        <a.div
            style={{ ...spring }}
            className={css(
                styles.container,
                props.topLeft
                    ? styles.topLeft
                    : props.topRight
                    ? styles.topRight
                    : css()
            )}
        >
            <h1
                style={{ marginBottom: '-1%', marginTop: '1%' }}
                className={css(
                    textStyles.heading32,
                    props.whiteText
                        ? localStyles.white
                        : props.offWhite
                        ? localStyles.offwhite
                        : localStyles.blue,
                    styles.head
                )}
            >
                {props.heading}
            </h1>
            <div
                className={css(
                    textStyles.para18,
                    props.whiteText ? localStyles.offwhite : localStyles.blue,
                    styles.para
                )}
            >
                {props.text?.split('/n').map((text, index) => (
                    <p>{text}</p>
                ))}
            </div>
            {props.buttonOptions ? (
                <div className={css(styles.mainButtonFlex)}>
                    {props.buttonOptions.map((buttonText, i) => {
                        return (
                            <MainButton
                                disabled={
                                    disabledButtons.includes(
                                        buttonText.toLowerCase()
                                    ) && stars.options[3] == 'no'
                                }
                                small
                                text={
                                    disabledButtons.includes(
                                        buttonText.toLowerCase()
                                    ) && stars.options[3] == 'no'
                                        ? 'HDFC Bank Debit Card not Activated'
                                        : buttonText
                                }
                                style={{
                                    zIndex: 2000,
                                    cursor: 'pointer',
                                    pointerEvents: 'auto'
                                }}
                                onClick={() => {
                                    props.clickOptions[i]();
                                    props.handleClick();
                                }}
                            />
                        );
                    })}
                </div>
            ) : (
                props.buttonText && (
                    <MainButton
                        style={{
                            zIndex: 2000,
                            marginTop: '2%',
                            cursor: 'pointer',
                            pointerEvents: 'auto'
                        }}
                        onClick={props.handleClick}
                        text={props.buttonText}
                    />
                )
            )}
        </a.div>
    ) : null;
};

const styles = StyleSheet.create({
    head: {
        // color:''
        fontFamily: 'Fredoka One',
        fontWeight: 600,
        color: colorThemes.brightBlue
        // fontStyle:'oblique'
    },
    para: {
        color: '#212121',
        fontFamily: 'Fredoka One',
        lineHeight: '3vh'
    },
    button: {
        boxSizing: 'border-box',
        margin: '1.4vh 1.4vh 0 0',
        padding: '1.8vh 1.3vw 1.8vh 1.3vw',
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '5px',
        color: colorThemes.darkerBlue,
        fontSize: '1.7vh',
        fontFamily: 'Fredoka One',
        border: '0.31vh #2f2f2f solid ',
        boxShadow: `none`,
        ':hover': {
            boxShadow: `${colorThemes.darkerBlue} 0px -0.4vh inset`
        },
        ':focus': {
            backgroundColor: colorThemes.darkerBlue,
            color: 'white',
            borderColor: 'white'
        },
        '@media (max-width: 1200px)': {
            padding: '3%'
        },
        '@media (max-width: 900px)': {
            padding: '2.5%',
            margin: '1.5% 1.5% 0 0'
        }
    },
    mainButton: {
        padding: '2.5vh 6.3vh 2.5vh 6.3vh',
        cursor: 'pointer',
        border: '0.2vh #212121 solid',
        backgroundColor: colorThemes.brightBlue,
        color: 'white',
        borderRadius: '5px',
        letterSpacing: '0.24vh',
        marginTop: '1vh',
        cursor: 'pointer',
        fontFamily: 'Fredoka One',
        fontWeight: 600,
        ':hover': {
            boxShadow: 'white 0px -3px inset'
        },
        ':focus': {
            color: colorThemes.brightBlue,
            backgroundColor: 'white',
            borderColor: colorThemes.brightBlue
        }
    },
    smallerMainButton: {
        boxSizing: 'border-box',
        padding: '1.5vh 3.3vh 1.5vh 3.3vh',
        marginTop: '0.5vh',
        width: '60%'
    },
    container: {
        position: 'absolute',
        zIndex: 2000,
        overflow: 'visible',
        fontFamily: 'Fredoka One',
        fontWeight: 600,
        boxShadow: '#08A4CF 0px -4px inset',
        // outline: '0.25vh #212121 solid',
        backgroundColor: 'rgba(255,255,255,0.9)',
        letterSpacing: '0.08vh',
        color: '#212121',
        width: '28%',
        pointerEvents: 'none',
        textAlign: 'left',
        padding: '1.5%',
        borderRadius: '3vh'
    },
    pureText: {
        position: 'absolute',
        zIndex: 2000,
        width: '33vw',
        pointerEvents: 'none',
        textAlign: 'center'
    },
    topLeft: {
        left: '10%',
        top: '20%'
    },
    topRight: {
        right: '10%',
        top: '20%'
    },
    disabled: {
        backgroundColor: 'grey',
        color: 'white'
    },
    mainButtonFlex: {
        marginTop: '2%'
    },
    none: {}
});
