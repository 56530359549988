import { useSpring } from '@react-spring/core';
import React from 'react';
import { springs } from '../../commonStyles';
import { useLoading, useStars, useSteps } from '../../stores';
import { Star } from './stars/Star';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { animated as a } from 'react-spring';
import { EndScreen } from './stars/EndScreen';
import { SFX } from './SFX';

const BlackScreen = (props) => {
    const { opacity } = useSpring({
        opacity: props.show ? 1 : 0,
        config: springs.cutscene
    });
    return (
        <a.div
            style={{
                opacity,
                backgroundColor: 'black',
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'
            }}
        ></a.div>
    );
};
export const ExperienceUI = () => {
    const steps = useSteps();
    const loader = useLoading();
    const stars = useStars();

    return (
        steps.step !== 0 &&
        loader.progress === loader.totalItems && (
            <>
                {steps.step < 3 && !stars.isLastScene && <Step1 />}
                {steps.step >= 2 && !stars.isLastScene && <Step2 />}
                {steps.step >= 3 && <Step3 />}
                <Star />
                <BlackScreen show={steps.cutscene} />
                {stars.isLastScene && <EndScreen />}
                <SFX />
            </>
        )
    );
};
