import { css, StyleSheet } from 'aphrodite';

const CouponLogo = ({
    logoUrl,
    alt,
    selectedCoupon,
    setselectedCoupon,
    logoIndex,
    getYesCount
}) => {
    return (
        <button
            style={{boxSizing:'border-box'}}
            className={`${css(styles['coupon-logo-btn'])} ${
                selectedCoupon === logoIndex
                    ? css(styles['selected-coupon'])
                    : ''
            } ${
                logoIndex < getYesCount()
                    ? css(styles['awarded-coupon'])
                    : css(styles['unawarded-coupon'])
            }`}
            onClick={() => {
                if (logoIndex < getYesCount()) {
                    setselectedCoupon(logoIndex);
                }
            }}
            disabled={logoIndex >= getYesCount() ? 'disabled' : ''}
        >
            <img src={logoUrl} alt={alt} width="100" />
        </button>
    );
};
export default CouponLogo;

const styles = StyleSheet.create({
    'coupon-logo-btn': {
        flex: '1 1 22%',
        '@media screen and (max-height: 635px)': {
            maxHeight: '10vh'
        },
        ':nth-child(n) img': {
            objectFit: 'contain',
            height: '100%',
            '@media screen and (max-height: 635px)': {
                width: '100%'
            }
        }
    },
    'selected-coupon': { // selectedCoupons
        boxShadow: 'rgba(0, 0, 100, 0.56) 0px 22px 70px 4px',
        border: '5px solid rgba(0, 90, 194, 1)'
        // background: 'rgba(0, 90, 194, 1)'
    },
    'awarded-coupon': {
        ':hover': {
            cursor: 'pointer'
        }
    },
    'unawarded-coupon': {
        ':nth-child(n) img': {
            filter: 'grayscale(100%)',
            '-webkit-filter': 'grayscale(100%)',
            '-moz-filter': 'grayscale(100%)',
            '-ms-filter': 'grayscale(100%)',
            '-o-filter': 'grayscale(100%)'
        }
    }
});
