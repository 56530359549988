import React, { useEffect, useRef, useState } from 'react';
import { useStars } from '../../../stores';
import { StyleSheet, css } from 'aphrodite';
import { animated as a } from 'react-spring';
import { useSpring } from '@react-spring/core';
import { useWindowSize, useMeasure } from 'react-use';
import { springs } from '../../../commonStyles';
import { nostar, starUrls } from '../../../constants';
function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}
// var x = getOffset( document.getElementById('yourElId') ).left;
const Image = (props) => {
    const { width, height } = useWindowSize();
    const ref = useRef();
    const measures = useMeasure();
    const { opacity, transform } = useSpring({
        from: { opacity: 0 },
        opacity: 1,
        // transform:'scale(1)',
        delay: props.filter ? 1000 : 4300
    });
    useEffect(() => {
        // setTimeout(()=>{
        props.setLast(getOffset(ref.current));
        // },[500])
    }, []);
    useEffect(() => {
        // if()
    });
    return (
        <>
            <a.div
                className={css(styles.starTop)}
                style={{ opacity: props.idx === 0 ? 0 : opacity }}
            >
                <a.img
                    style={{}}
                    height="100%"
                    width="100%"
                    ref={ref}
                    src={props.filter ? nostar : props.src}
                />
                
                {/* <div style={{position:'absolute',top:0,left:0,height:'100%',width:'100%',backgroundColor:'rgba(100,100,100,0.5)'}}></div> */}
            </a.div>
        </>
    );
};
const starSize = '11vh';
const starScale = 8;

export const Star = React.memo(() => {
    const stars = useStars();
    const bigContainerSize = useRef();
    const { width, height } = useWindowSize();
    const [animationPhase, setAnimationPhase] = useState(-1);
    const [localStars, setLocal] = useState(stars.stars);
    const [ref, measures] = useMeasure();
    const [bigContainerRef, bigContainerMeasures] = useMeasure();
    const [lastElementRendered, setLastElementRendered] = useState();
    const [startAnimation, setStart] = useState(false);
    const offsetRef = useRef();
    const [spring, api] = useSpring(() => ({}));
    const [strobe, strobeApi] = useSpring(() => ({}));
    const [lastSpring, lastSpringApi] = useSpring(() => {});
    const { opacity } = useSpring({
        opacity: stars.isLastScene ? 0 : 1,
        delay: stars.isLastScene ? 5600 : 0
    });

    useEffect(() => {}, [stars.stars]);

    useEffect(() => {
        bigContainerSize.current = getOffset(bigContainerRef.current);
        stars.reset();
    }, []);

    useEffect(() => {
        setTimeout(() => setAnimationPhase(0), [500]);
    }, [lastElementRendered]);
    useEffect(() => {
        if (animationPhase == 0) {
            api({
                from: {
                    strobe: 0,
                    transform: `translate(${width / 2 - measures.width}px,${
                        height / 2 - measures.height
                    }px) scale(0)`
                },
                strobe: 1,
                transform: `translate(${width / 2 - measures.width}px,${
                    height / 2 - measures.height
                }px) scale(${starScale})`,
                onRest: () => setTimeout(() => setAnimationPhase(1), [700]),
                config: springs.slow
            });
        } else if (animationPhase == 1) {
            api({
                from: {
                    strobe: 1,
                    transform: `translate(${width / 2 - measures.width}px,${
                        height / 2 - measures.height
                    }px) scale(${starScale}) rotate(0deg)`
                },
                strobe: 0,
                transform: `translate(${lastElementRendered.left}px,${lastElementRendered.top}px) scale(1) rotate(360deg)`,
                config: springs.slow.options,
                onRest: () => setTimeout(() => setAnimationPhase(2), [1600])
            });
        }
    }, [animationPhase]);

    useEffect(() => {
        setTimeout(() => {
            if (stars.isLastScene) {
                const { left, top } = getOffset(offsetRef.current);
                lastSpringApi.start({
                    transform: `translate(${
                        -left + width / 2 - bigContainerMeasures.width / 2
                    }px,${
                        -top + height / 2 - bigContainerMeasures.height / 2
                    }px) scale(1.6)`
                });
            }
        }, [0]);
    }, [stars.isLastScene]);

    return (
        <a.div
            style={{
                opacity,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 9100,
                pointerEvents: 'none'
            }}
        >
            <a.div
                style={{ opacity }}
                ref={bigContainerRef}
                className={css(styles.bigContainer)}
            >
                <div style={{ height: '100%', width: '100%' }} ref={offsetRef}>
                    {new Array(8).fill(0).map(() => {
                        return (
                            <img
                                style={{ width: starSize }}
                                height="100%"
                                src={starUrls[starUrls.length - 1]}
                                alt="starURL"
                            />
                        );
                    })}
                    <p className={css(styles.bottomText)}>Earn more stars, Win more offers!</p>
                </div>
            </a.div>
            <a.div style={{ opacity }} className={css(styles.container)}>
                {new Array(stars.stars + 1).fill(1).map((a, i) => {
                    return (
                        <Image
                            setLast={setLastElementRendered}
                            style={{ opacity }}
                            key={i}
                            idx={i}
                            height="100%"
                            src={i > 0 ? starUrls[i - 1] : starUrls[i]}
                            filter={
                                i > 0
                                    ? stars.options[i - 1] === 'yes'
                                        ? false
                                        : true
                                    : false
                            }
                        />
                    );
                })}
            </a.div>
            {/* <a.div
            > */}
            {animationPhase != 2 && (
                <a.img
                    height="100%"
                    ref={ref}
                    style={{
                        ...spring,
                        opacity:
                            stars.stars &&
                            animationPhase !== 2 &&
                            stars.options[stars.options.length - 1] !== 'no'
                                ? 1
                                : 0
                    }}
                    className={css(styles.popin)}
                    src={starUrls[stars.options.length - 1]}
                />
            )}
        </a.div>
    );
});

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        right: '-3%',
        top: '0%',
        height: starSize,
        zIndex: 600,
        display: 'flex',
        flexDirection: 'row-reverse',
        pointerEvents: 'none'
    },
    popin: {
        position: 'absolute',
        left: '0%',
        top: '0%',
        width: starSize,
        zIndex: 650,
        height: starSize,
        pointerEvents: 'none'
    },
    strobe: {
        position: 'absolute',
        zIndex: 600,
        left: '50%',
        top: '-10vh',
        width: '100vw',
        transform: 'translateX(-50%)',
        pointerEvents: 'none'
    },
    text: {
        position: 'absolute',
        zIndex: 650,
        top: '80%',
        left: '50%',
        transform: 'translateX(-50%)'

        // color:'white'
    },
    starTop: {
        width: starSize,
        height: '100%'
    },
    bigContainer: {
        position: 'absolute',
        top: 0,
        right: `calc(-3% + ${starSize})`,
        marginRight: '0.1%',
        marginTop: '0.08%',
        // marginRight:'6.8vh',
        height: starSize,
        // width:'50vw',
        zIndex: 590
        // backgroundColor:'grey'
    },
    bottomText:{
        color: 'white',
        textAlign:'right',
        fontFamily: 'Fredoka One',
        marginTop:'-1.2vh',
        marginRight:'1vw',
        fontSize: 'clamp(10px, 1.7vh, 100px)'
    }
});
