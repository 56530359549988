import React, { useEffect } from 'react';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';
import { useState } from 'react';

const waitingTexts = [
    'Setting up your bank account...',
    'Loading offers...',
    'Looking for discounts...'
];
export default function LoadingBar({ amount }) {
    const bar = useSpring({
        width: amount <= 100 ? amount + '%' : '100%'
    });
    const [text, set] = useState(0);
    let timeout;
    useEffect(() => {
        if (amount !== 100) {
            timeout = setTimeout(() => set((state) => state + 1), [5000]);
        }
        return () => clearTimeout(timeout);
    }, [text]);
    return (
        <>
            <div className={css(styles.barContainer)}>
                <animated.div style={bar} className={css(styles.loadingBar)} />
            </div>
            <p className={css(styles.percText)}>
                {String(amount <= 100 ? amount : 100).slice(0, 4)}%
            </p>
            <p className={css(styles.waiting)}>
                {waitingTexts[text % waitingTexts.length]}
            </p>
        </>
    );
}

LoadingBar.propTypes = {
    amount: PropTypes.number
};

const styles = StyleSheet.create({
    loadingBar: {
        backgroundColor: '#005AC2',
        height: '100%'
    },
    super: {},
    barContainer: {
        overflow: 'hidden',
        backgroundColor: 'C1DDFF',
        border: 'black 1px solid',
        borderRadius: '0.2vh',
        left: '49%',
        width: '30vw',
        transform: 'translate(-50%,-50%)',
        position: 'absolute',
        height: '0.3vh',
        top: '52%',
        verticalAlign: 'center'
    },
    container: {
        verticalAlign: 'middle',
        textAlign: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
    },
    bar: {
        backgroundColor: 'red',
        height: '2vh',
        width: '6vw'
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    percText: {
        position: 'absolute',
        lineHeight: '2.6vh',
        fontSize: '2.6vh',
        top: '52%',
        left: '65%',
        margin: 0,
        padding: 0,
        transform: 'translateY(-50%)',
        color: '#005AC2'
    },
    waiting: {
        position: 'absolute',
        top: '53%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '2.5vh'
    }
});
