import React, { useEffect, useRef, useState } from 'react';
import {
    useDebug,
    usePreferences,
    useSFX,
    useStars,
    useSteps
} from '../../stores';
import { animated as a } from 'react-spring';
import { css } from 'aphrodite';
import { commonStyles } from '../../commonStyles';
import { isIOS, isSafari } from 'react-device-detect';
import { useAudio } from 'react-use';
import { base } from '../../constants';

let firstAuth = false;

export const Cutscene = React.memo(
    ({ delay = 1500, timeBasedFunction = [], ...props }) => {
        const steps = useSteps();
        const [firstAuthRender, setFirstAuth] = useState(false);
        const videoAlreadyPaused = useRef(false);
        const stars = useStars();
        const { mute, setAmbient } = useSFX();
        const startTime = useRef();
        const timeBasedFunctionIdx = useRef(0);
        const {
            preferences: { avatar }
        } = usePreferences();
        const ref = useRef();
        let timeout3;
        const { speed } = useDebug();
        useEffect(() => {
            ref.current.playbackRate = speed;
        });
        useEffect(() => {
            const timeout1 = setTimeout(() => {
                steps.setCutscene(true);
            }, [1700 / speed]);
            const timeout2 = setTimeout(() => {
                if (!(isIOS  || isSafari)|| firstAuth) ref.current.play();
                // ref.current.play();
                startTime.current = new Date().getTime();
            }, [delay / speed]);

            return () => {
                clearInterval(timeout1);
                clearInterval(timeout2);
                // steps.setCutscene(false)
                if (timeout3) clearInterval(timeout3);
            };
        }, []);
        const handleEnd = () => {
            if (props.seemless) {
                props.onEnd && props.onEnd();
            } else {
                steps.setCutscene(false);
                timeout3 = setTimeout(() => {
                    props.onEnd && props.onEnd();
                }, [500]);
            }
        };
        useEffect(() => {
            if (firstAuth && !firstAuthRender) setFirstAuth(true);
        }, [firstAuthRender]);
        useEffect(() => {
            if (props.skip) {
                setTimeout(handleEnd, [2000 / speed]);
            }
        }, [props.skip]);
        const handleUpdate = ({ timestamp }) => {
            // const currentTime = new Date().getTime() - startTime.current
            // console.log(ref.current.currentTime)
            // console.log( timeBasedFunction[timeBasedFunctionIdx.current][0],timeBasedFunction[timeBasedFunctionIdx.current][0]>=currentTime,currentTime,timeBasedFunction[timeBasedFunctionIdx.current])
            if (
                timeBasedFunctionIdx.current < timeBasedFunction?.length &&
                timeBasedFunction[timeBasedFunctionIdx.current][0] <=
                    ref.current.currentTime * 1000
            ) {
                timeBasedFunction[timeBasedFunctionIdx.current][1]();
                timeBasedFunctionIdx.current += 1;
            }
            if (
                ref.current.currentTime * 1000 >= 4000 &&
                props.lastScene &&
                !videoAlreadyPaused.current
            ) {
                ref.current.pause();
                videoAlreadyPaused.current = true;
            }
        };
        useEffect(() => {
            stars.isLastScene && ref.current.play();
        }, [stars.isLastScene]);
        return (
            <a.div
                style={{
                    // objectFit: 'fill',
                    zIndex: 300,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    pointerEvents: 'all',
                    height: '100%',
                    width: '100%',
                    // marginLeft:'30%',
                    backgroundColor: 'black',
                    ...props.style
                    // opacity:0,
                    // transform:'translateX(30%)'
                }}
                className={css(commonStyles.fullscreen)}
            >
                <a.video
                    onTimeUpdate={handleUpdate}
                    ref={ref}
                    loop={props.loop}
                    // playbackRate={5}
                    onEnded={handleEnd}
                    style={{ ...props.style }}
                    width="100%"
                    height="100%"
                    // loop={props.loop}
                    playsInline={true}
                    autoPlay={false}
                    controls={false}
                    muted={isIOS || isSafari ? true : mute}
                >
                    <source
                        src={
                            base + (avatar === 1 ? props.video.male : props.video.female)
                        }
                    />
                </a.video>
                {!firstAuthRender && (isIOS || isSafari) && (
                    <div
                        style={{
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            paddingTop: '40vh',
                            position: 'absolute',
                            textAlign: 'center'
                        }}
                        onClick={() => {
                            ref.current.play();
                            let amb = document.getElementById('ambientAudio');
                            amb.play();
                            setFirstAuth(true);
                            firstAuth = true;
                        }}
                    >
                        <div
                            style={{
                                color: 'white',
                                fontSize: '3.5vh',
                                lineHeight: '5vh'
                            }}
                        >
                            Tap anywhere to Start
                        </div>
                    </div>
                )}
            </a.div>
        );
    }
);

// const styles = StyleSheet.create({
//     ui: {
//         position: 'absolute',
//         left: '10%',
//         top: '45%',
//         width: '32%',
//         height: '55%',
//         transform: 'translateY(-10%)',
//         textAlign: 'left',
//         zIndex: 9000,
//         pointerEvents: 'none'
//     }
// });
