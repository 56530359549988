import React, { useRef, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { textStyles } from '../../commonStyles';
import { MainButton, OptionsButton } from '../UI/OptionsButton';
import { usePreferences } from '../../stores';
import { useSpring } from '@react-spring/core';
import { animated as a } from 'react-spring';
import { introductionImages } from '../../constants';

export const Avatar = () => {
    const prefs = usePreferences();
    const ref = useRef();
    const [selected, setSelected] = useState(1);
    const check = () => {
        return !prefs.preferences.avatar && prefs.preferences.g;
    };
    const spring = useSpring({ opacity: check() ? 1 : 0 });
    const { opacity } = useSpring({
        opacity: selected === 1 ? 0 : 1
    });

    const constructLink = (param) => {
        prefs.setPreferences({ avatar: selected });
    };
    return (
        <>
            {prefs.preferences.g && (
                <a.div style={spring}>
                    <a.img
                        style={{ opacity: opacity.to((x) => 1 - x) }}
                        className={css(styles.bg)}
                        src={introductionImages.male}
                    />
                    <a.img
                        style={{ opacity: opacity.to((x) => x) }}
                        className={css(styles.bg)}
                        src={introductionImages.female}
                    />
                </a.div>
            )}

            <a.div style={spring} className={css(styles.container)}>
                <h1
                    style={{ marginTop: '13%', marginBottom: '1.3%' }}
                    className={css(textStyles.heading32)}
                >
                    Choose your Character
                </h1>
                <p
                    style={{ margin: '0% 0 0% 0' }}
                    className={css(textStyles.para16)}
                >
                    Pick one of us to play the game
                </p>
                <div ref={ref} className={css(styles.flex)}>
                    <OptionsButton
                        onClick={() => setSelected(1)}
                        text="Hitesh"
                    />
                    <OptionsButton onClick={() => setSelected(2)} text="Hema" />
                </div>
                <div className={css(styles.buttonMargin)}>
                    <MainButton
                        onClick={() => constructLink('avatar')}
                        text="Start The Journey"
                    />
                </div>
            </a.div>
        </>
    );
};

const styles = StyleSheet.create({
    avatar: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '92%',
        width: '50%',
        zIndex: 750,
        boxSizing: 'border-box',
        padding: '5% 2% 2% 5%',
        textAlign: 'left'
    },
    container: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        height: '92%',
        zIndex: 750,
        width: '50%',
        textAlign: 'left'
    },
    flex: {
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 4% 0 0%',
        width: '70%',
        marginTop: '3%',
        '@media (max-width: 900px)': {
            width: '100%'
        }
    },
    buttonMargin: {
        marginTop: '10%',
        '@media (max-width: 900px)': {
            marginTop: '5%'
        }
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
});
